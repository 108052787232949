import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@material-ui/core";
import FlightModalDetail from "./FlightModalDetail";
import { agentbookinghistoryDispatch } from "../../reducers/UserReducer";
import { useHistory } from "react-router-dom";
import { FlightMailTicketDispatch } from "../../reducers/HomeReducer";
import {
  setBookingHistoryID,
  toggleFlightDetailModal,
  setSelectedBookingHistory,
  toggleCancelModal,
} from "../../reducers/UiReducer";
import CancelTicketModal from "./CancelTicketModal";
const tableHeaders = [
  "ORDER_ID",
  "DEPART FROM",
  "JOURNEY TO",
  "DATE OF BOOKING",
  "JOURNEY DATE",
  "TICKET COST",
  "STATUS",
  "ACTION",
];

const BookingHistory = () => {
  const dispatch = useDispatch();
  const [val, setVal] = useState("");
  const [start, setSDate] = useState(new Date());
  const [end, setEDate] = useState(new Date());
  const { bookinghistory, bookinghistoryagent, agentlogin } = useSelector(
    (state) => state.user
  );
  const history = useHistory();
  useEffect(() => {
    dispatch(
      agentbookinghistoryDispatch(agentlogin && agentlogin.result.result.id)
    );
  }, []);
  const [data, setData] = useState("");
  console.log("Booking", bookinghistoryagent);
  const handleMail = (item) => {
    let formMail = {
      orderId: item.orderId,
      userEmail: item.userEmail,
    };
    // dispatch(FlightMailTicketDispatch(formMail));
    dispatch(FlightMailTicketDispatch(formMail));
    window.alert("Ticket Send Successfully");
  };
  const handleDate = (event) => {
    // console.log("first",event.target.value)
    setSDate(new Date(event.target.value));
  };
  const handleDate2 = (event) => {
    // console.log("first2",event.target.value)
    setEDate(new Date(event.target.value));
  };
  const handleFilter = () => {
    let results = [];
    results =
      bookinghistoryagent &&
      bookinghistoryagent.result &&
      bookinghistoryagent.result.flights.filter((item) => {
        var datef = new Date(item.dob);
        return datef >= start && datef <= end;
      });
    setData(results);
    console.log("results", results);
  };
  const handleSearch = (event) => {
    let value = event.target.value.toLowerCase();
    let results = [];
    console.log(value);
    // setVal(value);
    if (event.target.value.toLowerCase() === "new") {
      results =
        bookinghistoryagent &&
        bookinghistoryagent.result &&
        bookinghistoryagent.result.flights.slice().sort((a, b) => {
          return b.id - a.id;
        });
      setData(results);
    } else if (event.target.value.toLowerCase() === "old") {
      results =
        bookinghistoryagent &&
        bookinghistoryagent.result &&
        bookinghistoryagent.result.flights.slice().sort((a, b) => {
          return a.id - b.id;
        });
      setData(results);
    } else if (event.target.value.toLowerCase() === "lh") {
      results =
        bookinghistoryagent &&
        bookinghistoryagent.result &&
        bookinghistoryagent.result.flights.slice().sort((a, b) => {
          return a.amount - b.amount;
        });
      setData(results);
    } else if (event.target.value.toLowerCase() === "hl") {
      results =
        bookinghistoryagent &&
        bookinghistoryagent.result &&
        bookinghistoryagent.result.flights.slice().sort((a, b) => {
          return b.ticketCost - a.ticketCost;
        });
      setData(results);
    } else {
      results =
        bookinghistoryagent &&
        bookinghistoryagent.result &&
        bookinghistoryagent.result.flights.filter((item) => {
          return item.type.toLowerCase().includes(value.toLowerCase());
        });
      setData(results);
    }
    // results =
    //   bookinghistoryagent &&
    //   bookinghistoryagent.result &&
    //   bookinghistoryagent.result.flights.filter((item) => {
    //     return (
    //       item.orderId.toLowerCase().includes(value.toLowerCase()) ||
    //       item.userEmail.toLowerCase().includes(value.toLowerCase()) ||
    //       item.origin.toLowerCase().includes(value.toLowerCase()) ||
    //       item.destination.toLowerCase().includes(value.toLowerCase()) ||
    //       item.flightType.toLowerCase().includes(value.toLowerCase()) ||
    //       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
    //       item.journeyType.toLowerCase().includes(value.toLowerCase()) ||
    //       item.supplier.toLowerCase().includes(value.toLowerCase()) ||
    //       item.bookingStatus.toLowerCase().includes(value.toLowerCase()) ||
    //       item.travelDate.toLowerCase().includes(value.toLowerCase())
    //     );
    //   });
    // setData(results);
    // console.log(results);
  };
  console.log("Result", data);
  // const handleSearch =

  return (
    <>
      <div className="row " style={{ padding: 15 }}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <input
              type="date"
              style={{
                padding: "13px",
                cursor: "pointer",
                border: "1px solid",
              }}
              placeholder="Select Date"
              // value={val}
              onChange={handleDate}
              //   className="form-control form-control-sm"
            />
            <input
              type="date"
              style={{
                padding: "13px",
                marginLeft: 10,
                cursor: "pointer",
                border: "1px solid",
              }}
              placeholder="Select Date"
              // value={val}
              onChange={handleDate2}
              //   className="form-control form-control-sm"
            />
            <span
              style={{
                marginLeft: 10,
                background: "bisque",
                padding: 14,
                cursor: "pointer",
                fontSize: 18,
                fontWeight: "bold",
              }}
              onClick={handleFilter}
            >
              {" "}
              Search
            </span>
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              {/* <span>Filter</span> */}
              <select
                onChange={handleSearch}
                name="Filter by"
                id="cars"
                style={{ padding: 10, border: "1px solid grey" }}
              >
                <option value="booked"> Filter</option>
                <option value="booked"> Booked</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </Box>
            <Box display="flex" alignItems="center" ml={"5px"}>
              {/* <span>Filter</span> */}
              <select
                onChange={handleSearch}
                name="Sort by"
                id="cars"
                style={{ padding: 10, border: "1px solid grey" }}
              >
                <option value="booked"> Sort</option>
                <option value="new"> Newest to Oldest</option>
                <option value="old">Oldest to Newest</option>
                <option value="lh">Price Low-High</option>
                <option value="hl">Price High-Low</option>
              </select>
            </Box>
          </Box>
        </Box>

        {/* </Box> */}
        <div className="col">
          <table className="table bg-white rounded shadow-sm  table-hover">
            <thead>
              <tr>
                <th scope="col" width="50">
                  #
                </th>
                {tableHeaders.map((i) => {
                  return (
                    <th scope="col" style={{ fontFamily: "monospace" }}>
                      {i}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data && data.length > 0
                ? data.map((i, index) => {
                    return (
                      <tr>
                        <th
                          scope="row"
                          style={{ fontSize: 12, fontFamily: "Poppins" }}
                        >
                          {index + 1}
                        </th>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.orderId}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.origin}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.destination}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.dob}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.travelDate}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          ₹ {i.ticketCost}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.bookingStatus}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          <i
                            class="fa-solid fa-eye"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              dispatch(toggleFlightDetailModal(true));
                              dispatch(setBookingHistoryID(i.id));
                            }}
                          ></i>
                          <i
                            class="fa-solid fa-envelope"
                            style={{ marginLeft: 5, cursor: "pointer" }}
                            onClick={() => handleMail(i)}
                          ></i>
                         
                          <i
                            class="fa-solid fa-print"
                            onClick={() =>
                              history.push({
                                pathname: "/ticketdisplay",
                                state: i,
                              })
                            }
                            style={{ marginLeft: 5, cursor: "pointer" }}
                          ></i>
                          <i
                            class="fa fa-ticket"
                            aria-hidden="true"
                            style={{
                              marginLeft: 5,
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => {
                              dispatch(toggleCancelModal(true));
                              dispatch(setSelectedBookingHistory(i));
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })
                : bookinghistoryagent &&
                  bookinghistoryagent?.result?.flights?.length > 0 &&
                  bookinghistoryagent?.result?.flights.map((i, index) => {
                    return (
                      <tr>
                        <th
                          scope="row"
                          style={{ fontSize: 12, fontFamily: "Poppins" }}
                        >
                          {index + 1}
                        </th>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.orderId}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.origin}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.destination}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.dob}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.travelDate}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          ₹ {i.ticketCost}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          {i.bookingStatus}
                        </td>
                        <td style={{ fontSize: 12, fontFamily: "Poppins" }}>
                          <i
                            class="fa-solid fa-eye"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // dispatch(toggleFlightDetailModal(true));
                              // dispatch(setBookingHistoryID(i.id));
                              dispatch(toggleFlightDetailModal(true));
                              dispatch(setBookingHistoryID(i.id));
                            }}
                          ></i>
                          <i
                            class="fa-solid fa-envelope"
                            style={{ marginLeft: 5, cursor: "pointer" }}
                            onClick={() => handleMail(i)}
                          ></i>
                          <i
                            class="fa-solid fa-print"
                            onClick={() =>
                              history.push({
                                pathname: "/ticketdisplay",
                                state: i,
                              })
                            }
                            style={{ marginLeft: 5, cursor: "pointer" }}
                          ></i>
                          <i
                            class="fa fa-ticket"
                            aria-hidden="true"
                            style={{
                              marginLeft: 5,
                              cursor: "pointer",
                              color: "red",
                            }}
                            onClick={() => {
                              dispatch(toggleCancelModal(true));
                              dispatch(setSelectedBookingHistory(i));
                            }}
                          ></i>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
      <FlightModalDetail />
      <CancelTicketModal />
    </>
  );
};

export default BookingHistory;
