import React from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import TitleText from './TitleText';
import BodyText from './BodyText';
import Seat from '../../assets/images/seat.png';
import moment from 'moment';
import {
	toggleFlightModal,
	setFlightId,
	setEtravFlightFare,
} from '../../reducers/UiReducer';
import {
	fareRuleEtravSuccess,
	fareRuleSuccess,
	FlightFareRuleDispatch,
	FlightFareRuleEtravDispatch,
} from '../../reducers/HomeReducer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import {
	fareRuleTripJackSuccess,
	FlightFareRuleTripJackDispatch,
} from '../../reducers/TripjackReducer';

const OneWayDesktopRow = ({
	item,
	loading,
	timeConvert,
	location,
	onewayflightdata,
	onewayflightdataEtrav,
	convertH2M,
	net,
	vl,
}) => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const history = useHistory();
	const dispatch = useDispatch();
	const { orderID, currency } = useSelector((state) => state.home);
	const { curr } = useSelector((state) => state.ui);
	const { AdultCount, ChildCount, InfantCount } = location.state.body;
	var todayDate = moment(new Date()).format('MM-DD-YYYY'); //Instead of today date, We can pass whatever date

	// console.log('Ad', AdultCount);
	// console.log('c', ChildCount);
	// console.log('i', InfantCount);
	const getLayoverDuration = (startTime: any, endTime: any) => {
		let endDate = moment(endTime);
		let startDate = moment(startTime);
		let duration = moment.duration(endDate.diff(startDate));
		return convertMinsToHrsMins(Number(duration.asMinutes()));
	};
	const convertMinsToHrsMins = (mins: number) => {
		if (mins >= 1440) {
			let dy = Math.floor(mins / 1440);
			//console.log(dy);
			let min = mins % 1440;
			//console.log(min);
			let h = Math.floor(min / 60);
			let m = min % 60;
			let hr = h < 10 ? '0' + h : h;
			let mi = m < 10 ? '0' + m : m;

			return dy + 'd ' + hr + 'h ' + mi + 'm';
		} else {
			let h = Math.floor(mins / 60);
			let m = mins % 60;
			let hr = h < 10 ? '0' + h : h;
			let min = m < 10 ? '0' + m : m;
			return hr + 'h ' + min + 'm';
		}
	};
	const timedifference = (t1, t2) => {
		const date1 = new Date(t1);
		const date2 = new Date(t2);
		const diff = date2.getTime() - date1.getTime();
		return Math.abs(Math.floor(diff / 1000 / 60));
	};
	return (
		<>
			<Box
				display='flex'
				style={{
					boxShadow: '3px 3px 16px -4px rgb(0 0 0 / 30%)',
					padding: 8,
					background: 'white',
				}}
				justifyContent='space-between'
				alignItems='center'
				mt={'20px'}>
				<Box display='flex' alignItems='center'>
					{loading ? (
						<Skeleton variant='circle' width={40} height={40} />
					) : (
						<img
							src={`https://nitish.musafirbazar.com/static/media/${
								item.AirlineCode
									? item.AirlineCode
									: item.Airline_Code
									? item.Airline_Code
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''
							}.gif`}
							width='50px'
							height='25px'></img>
					)}

					<Box alignItems='center' ml={'5px'}>
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								color: '#3c3c3c',
								fontSize: 14,
							}}>
							{loading ? (
								<Skeleton variant='text' width='60px' />
							) : item.Airline_Code ? (
								item.Segments[0].Airline_Name
							) : item.AirlineCode ? (
								item.Segments[0].slice(0, 1).map((i) => {
									return i.Airline.AirlineName;
								})
							) : item.Supplier === 'TJK' ? (
								item.sI[0].fD.aI.name
							) : (
								''
							)}
						</TitleText>
						{loading ? (
							<Skeleton variant='text' width='60px' />
						) : (
							<BodyText
								three
								textStyle={{
									fontFamily: 'Roboto Serif, sans-serif',
									// color: '#8f8f8f',
									fontSize: 12,
									fontWeight: 600,
								}}>
								{item.Airline_Code
									? item.Segments[0].Airline_Code
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.AirlineCode;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.aI.code
									: ''}
								-
								{item.Airline_Code
									? item.Segments[0].Flight_Number
									: item.AirlineCode
									? item.Segments[0].slice(0, 1).map((i) => {
											return i.Airline.FlightNumber;
									  })
									: item.Supplier === 'TJK'
									? item.sI[0].fD.fN
									: ''}
							</BodyText>
						)}
					</Box>
				</Box>

				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: 14,
							}}>
							{item.Origin
								? item.Origin
								: item.AirlineCode
								? item.FareRules[0].Origin
								: item.Supplier === 'TJK'
								? item.sI[0].da.cityCode
								: ''}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText three textStyle={{ fontSize: 14 }}>
							{item.Airline_Code
								? item.Segments[0].Departure_DateTime.substr(11, 5)
								: item.AirlineCode
								? item.Segments[0].slice(0, 1).map((i) => {
										return i.Origin.DepTime.substr(11, 5);
								  })
								: item.Supplier === 'TJK'
								? item.sI[0].dt.substr(11, 5)
								: ''}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' textAlign='center'>
					{loading ? (
						<Skeleton variant='text' width='100px' />
					) : (
						<BodyText
							three
							textStyle={{
								color: 'rgb(19 20 20)',
								fontSize: 14,
							}}>
							{/* {console.log(
                "Duration",
                // convertH2M(
                item.Segments.length === 2
                  ? item.Airline_Code
                    ? item.Segments.filter((i, index) => index === 0).map(
                        (it) => {
                          return it.Duration;
                        }
                      )
                    : ""
                  : ""
                // )
              )} */}
							{timeConvert(
								item.Airline_Code
									? item.Segments.length === 2
										? timedifference(
												item.Segments.filter((i, index) => index === 0).map(
													(it) => it.Departure_DateTime
												),
												item.Segments.filter((i, index) => index === 1).map(
													(it) => it.Arrival_DateTime
												)
										  )
										: item.Segments.length === 1
										? timedifference(
												item.Segments.filter((i, index) => index === 0).map(
													(it) => it.Departure_DateTime
												),
												item.Segments.filter((i, index) => index === 0).map(
													(it) => it.Arrival_DateTime
												)
										  )
										: item.Segments.length === 3
										? timedifference(
												item.Segments.filter((i, index) => index === 0).map(
													(it) => it.Departure_DateTime
												),
												item.Segments.filter((i, index) => index === 2).map(
													(it) => it.Arrival_DateTime
												)
										  )
										: ''
									: item.AirlineCode
									? item.Segments[0].length == 2
										? timedifference(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Origin.DepTime;
													}),
												item.Segments[0]
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.Destination.ArrTime;
													})
										  )
										: item.Segments[0].length == 1
										? item.Segments[0].map((i) =>
												timedifference(
													item.Segments[0]
														.filter((i, index) => index === 0)
														.map((it) => {
															return it.Origin.DepTime;
														}),
													item.Segments[0]
														.filter((i, index) => index === 0)
														.map((it) => {
															return it.Destination.ArrTime;
														})
												)
										  )
										: item.Segments[0].length == 3
										? timedifference(
												item.Segments[0]
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.Origin.DepTime;
													}),
												item.Segments[0]
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.Destination.ArrTime;
													})
										  )
										: ''
									: item.Supplier === 'TJK'
									? item.sI.length == 2
										? timedifference(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.dt;
													}),
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.at;
													})
										  )
										: item.sI.length == 1
										? item.sI.map((i) =>
												timedifference(
													item.sI
														.filter((i, index) => index === 0)
														.map((it) => {
															return it.dt;
														}),
													item.sI
														.filter((i, index) => index === 0)
														.map((it) => {
															return it.at;
														})
												)
										  )
										: item.sI.length == 3
										? timedifference(
												item.sI
													.filter((i, index) => index === 0)
													.map((it) => {
														return it.dt;
													}),
												item.sI
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.at;
													})
										  )
										: ''
									: ''
							)}
						</BodyText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<BodyText
							two
							textStyle={{
								color: 'green',
								fontWeight: 'bold',
								fontSize: '14px',
							}}>
							{item.AirlineCode
								? item.Segments[0].length === 1
									? 'Non Stop'
									: item.Segments[0].length === 2
									? '1 Stop'
									: item.Segments[0].length === 3
									? '2 Stop'
									: ''
								: item.Airline_Code
								? item.Segments.length === 1
									? 'Non Stop'
									: item.Segments.length === 2
									? '1 Stop'
									: item.Segments.length === 3
									? '2 Stop'
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? 'Non Stop'
									: item.sI.length === 2
									? '1 Stop'
									: item.sI.length === 3
									? '2 Stop'
									: ''
								: ''}
						</BodyText>
					)}
				</Box>
				<Box>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText
							three
							textStyle={{
								fontFamily: 'Josefin Sans, sans-serif',
								fontWeight: 500,
								fontSize: 14,
							}}>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Destination;
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Destination;
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.Destination.Airport.AirportCode;
											})
									: item.Segments[0].length == 1
									? item.Segments[0].map((it) => {
											return it.Destination.Airport.AirportCode;
									  })
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.aa.cityCode;
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.aa.cityCode;
											})
									: ''
								: ''}
						</TitleText>
					)}
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<TitleText three textStyle={{ fontSize: 14 }}>
							{item.Airline_Code
								? item.Segments.length === 1
									? item.Segments.filter((i, index) => index === 0).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 2
									? item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: item.Segments.length === 3
									? item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Arrival_DateTime.substr(11, 5);
											}
									  )
									: ''
								: item.AirlineCode
								? item.Segments[0].length == 3
									? item.Segments[0]
											.filter((i, index) => index === 2)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 2
									? item.Segments[0]
											.filter((i, index) => index === 1)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: item.Segments[0].length == 1
									? item.Segments[0]
											.filter((i, index) => index === 0)
											.map((i) => {
												return i.Destination.ArrTime.substr(11, 5);
											})
									: ''
								: item.Supplier === 'TJK'
								? item.sI.length === 1
									? item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 2
									? item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: item.sI.length === 3
									? item.sI
											.filter((i, index) => index === 2)
											.map((it) => {
												return it.at.substr(11, 5);
											})
									: ''
								: ''}
						</TitleText>
					)}
				</Box>
				<Box alignItems='center' display='flex'>
					{loading ? (
						<Skeleton variant='text' width='60px' />
					) : (
						<Box>
							<TitleText
								textStyle={{
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									fontSize: 20,
								}}>
								<span style={{ marginRight: 5 }}>{'₹'}</span>
								{item.Airline_Code
									? Number(
											Math.round(Number(Math.round(item.Fares.Total_Amount)))
									  )
									: item.AirlineCode
									? Number(
											Math.round(
												Number(
													Number(Math.round(item.Fare.Total_Amount)) -
														Number(Math.round(item.Fare.MFB_Discount))
												)
											)
									  )
									: item.Supplier === 'TJK'
									? Number(
											Math.round(
												Number(Math.round(item.totalPriceList.fd.Total_Amount))
											)
									  )
									: ''}
							</TitleText>
							{net ? (
								<span style={{ color: 'grey', fontSize: 12 }}>
									NET{' '}
									{item.Airline_Code
										? Number(
												Math.round(
													Number(
														Number(Math.round(item.Fares.Total_Amount)) -
															Number(
																Math.round(net ? item.Fares.agentMarkup : 0)
															)
													)
												)
										  )
										: item.AirlineCode
										? Number(
												Math.round(
													Number(
														Number(Math.round(item.Fare.Total_Amount)) -
															Number(Math.round(item.Fare.MFB_Discount)) -
															Number(
																Math.round(net ? item.Fare.agentMarkup : 0)
															)
													)
												)
										  )
										: ''}
								</span>
							) : (
								''
							)}
						</Box>
					)}

					{loading ? (
						<Skeleton variant='text' width='60px' height='60px' />
					) : (
						<>
							<Box
								onClick={() => {
									history.push({
										pathname: '/summaryflight',
										state: {
											OneflightData: item,
											body: location.state.body,
											orderID: orderID.result,
											IDTBO:
												onewayflightdata &&
												onewayflightdata.result &&
												onewayflightdata.result.Response &&
												onewayflightdata.result.Response.TraceId,
											IDEtrav:
												onewayflightdataEtrav &&
												onewayflightdataEtrav.result &&
												onewayflightdataEtrav.result.Search_Key,
										},
									});
								}}
								style={{
									color: 'white',
									background: 'midnightblue',
									padding: '6px 14px',
									borderRadius: 8,
									marginLeft: 5,
									cursor: 'pointer',
									fontFamily: 'Josefin Sans, sans-serif',
									fontWeight: 500,
									boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
								}}>
								<TitleText textStyle={{ fontSize: 14 }}>Book Now</TitleText>
							</Box>
							{item.Airline_Code ? (
								<span>*</span>
							) : item.Supplier === 'TJK' ? (
								<span>**</span>
							) : (
								''
							)}
						</>
					)}
				</Box>
			</Box>

			<Box
				style={{
					padding: '6px 10px',
					background: '#f5f6f6',
					borderBottomLeftRadius: 4,
					borderBottomRightRadius: 4,
					borderTop: '1px solid #e2e2e2',
				}}
				justifyContent='space-between'
				alignItems='center'
				display='flex'>
				<Box display='flex' alignItems='center'>
					{loading ? (
						<Skeleton variant='text' width='60px' height='40px' />
					) : (
						<Box
							style={{
								padding: '2px 10px',
								background: item.AirlineCode
									? item.FareClassification
										? item.FareClassification.Color
										: ''
									: '#f56d6d',
								borderRadius: 4,
								boxShadow: '3px 3px 12px -6px rgb(0 0 0 / 70%)',
							}}>
							<BodyText
								textStyle={{
									color: item.AirlineCode
										? item.FareClassification
											? item.FareClassification.Color === 'Blue'
												? 'white'
												: 'black'
											: 'white'
										: 'white',
									fontSize: 10,
								}}>
								{item.AirlineCode
									? item.FareClassification?.Type
									: item.Airline_Code
									? item.Fares.Warning === null || item.Fares.Warning === ''
										? item.Fares.FareDetails[0].FareClasses[0].Class_Desc
										: 'MBFARE'
									: item.Supplier === 'TJK'
									? item.totalPriceList.fareIdentifier
									: ''}
							</BodyText>
						</Box>
					)}
					{item.Supplier === 'TBO' ? (
						item.IsRefundable ? (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'green',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 10,
								}}>
								R
							</span>
						) : (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'red',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 10,
								}}>
								N
							</span>
						)
					) : item.Supplier === 'ETR' ? (
						item.Fares.Refundable ? (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'green',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 10,
								}}>
								R
							</span>
						) : (
							<span
								style={{
									color: 'white',
									marginLeft: 15,
									background: 'red',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 10,
								}}>
								N
							</span>
						)
					) : (
						''
					)}

					{item.Supplier === 'TBO' ? (
						item.Fare ? (
							<>
								<span
									style={{
										color: 'black',
										marginLeft: 15,
										background: 'yellow',
										padding: '2px 10px',
										borderRadius: 5,
										fontSize: 12,
									}}>
									<img
										src={Seat}
										alt='seat'
										style={{ width: 25, height: 25 }}
									/>
									{item.Segments[0]
										.filter((i, index) => index === 0)
										.map((it) => {
											return it.NoOfSeatAvailable;
										})}
								</span>
							</>
						) : (
							<></>
						)
					) : item.Fares.Seats_Available ? (
						<>
							<span
								style={{
									color: 'black',
									marginLeft: 15,
									background: 'yellow',
									padding: '2px 10px',
									borderRadius: 5,
									fontSize: 12,
								}}>
								<img src={Seat} alt='seat' style={{ width: 25, height: 25 }} />
								{`${item.Fares.Seats_Available}`}
							</span>
						</>
					) : (
						<></>
					)}
				</Box>
				{item.Supplier === 'TBO' ? (
					item.Segments[0].length == 1 ? (
						''
					) : (
						<span>
							{`Layover ${timeConvert(
								item.AirlineCode
									? item.Segments[0].length == 2
										? Number(
												timedifference(
													item.Segments[0]
														.filter((i, index) => index === 0)
														.map((it) => {
															return it.Destination.ArrTime;
														}),
													item.Segments[0]
														.filter((i, index) => index === 1)
														.map((it) => {
															return it.Origin.DepTime;
														})
												)
										  )
										: item.Segments[0].length == 3
										? Number(
												Number(
													timedifference(
														item.Segments[0]
															.filter((i, index) => index === 0)
															.map((it) => {
																return it.Destination.ArrTime;
															}),
														item.Segments[0]
															.filter((i, index) => index === 1)
															.map((it) => {
																return it.Origin.DepTime;
															})
													) +
														timedifference(
															item.Segments[0]
																.filter((i, index) => index === 1)
																.map((it) => {
																	return it.Destination.ArrTime;
																}),
															item.Segments[0]
																.filter((i, index) => index === 2)
																.map((it) => {
																	return it.Origin.DepTime;
																})
														)
												)
										  )
										: ''
									: ''
							)}`}
						</span>
					)
				) : item.Supplier === 'ETR' ? (
					item.Segments.length === 1 ? (
						''
					) : (
						<span>
							{`Layover
      ${timeConvert(
				Number(
					item.Segments.length === 2
						? timedifference(
								item.Segments.filter((i, index) => index === 0).map((it) => {
									return it.Arrival_DateTime;
								}),
								item.Segments.filter((i, index) => index === 1).map((it) => {
									return it.Departure_DateTime;
								})
						  )
						: item.Segments.length === 3
						? Number(
								timedifference(
									item.Segments.filter((i, index) => index === 0).map((it) => {
										return it.Arrival_DateTime;
									}),
									item.Segments.filter((i, index) => index === 1).map((it) => {
										return it.Departure_DateTime;
									})
								) +
									timedifference(
										item.Segments.filter((i, index) => index === 1).map(
											(it) => {
												return it.Arrival_DateTime;
											}
										),
										item.Segments.filter((i, index) => index === 2).map(
											(it) => {
												return it.Departure_DateTime;
											}
										)
									)
						  )
						: ''
				)
			)}`}
						</span>
					)
				) : item.Supplier === 'TJK' ? (
					item.sI.length === 1 ? (
						''
					) : (
						<span>
							{`Layover
          ${timeConvert(
						Number(
							item.sI.length === 2
								? timedifference(
										item.sI
											.filter((i, index) => index === 0)
											.map((it) => {
												return it.at;
											}),
										item.sI
											.filter((i, index) => index === 1)
											.map((it) => {
												return it.dt;
											})
								  )
								: item.sI.length === 3
								? Number(
										timedifference(
											item.sI
												.filter((i, index) => index === 0)
												.map((it) => {
													return it.at;
												}),
											item.sI
												.filter((i, index) => index === 1)
												.map((it) => {
													return it.dt;
												})
										) +
											timedifference(
												item.sI
													.filter((i, index) => index === 1)
													.map((it) => {
														return it.at;
													}),
												item.sI
													.filter((i, index) => index === 2)
													.map((it) => {
														return it.dt;
													})
											)
								  )
								: ''
						)
					)}`}
						</span>
					)
				) : (
					''
				)}
				<Box
					onClick={() => {
						dispatch(toggleFlightModal(true));
						dispatch(
							setFlightId(
								item.Airline_Code
									? item.Fares.Fare_Id
									: item.AirlineCode
									? item.ResultIndex
									: item.Supplier === 'TJK'
									? item.totalPriceList.id
									: ''
							)
						);

						if (item.Airline_Code) {
							dispatch(fareRuleSuccess(null));
							dispatch(
								FlightFareRuleEtravDispatch(
									onewayflightdataEtrav &&
										onewayflightdataEtrav.result &&
										onewayflightdataEtrav.result.Search_Key,
									item.Flight_Key,
									item.Fares.Fare_Id
								)
							);
						} else if (item.AirlineCode) {
							dispatch(fareRuleEtravSuccess(null));
							dispatch(
								FlightFareRuleDispatch(
									item.ResultIndex,
									onewayflightdata &&
										onewayflightdata.result &&
										onewayflightdata.result.Response.TraceId
								)
							);
						} else if (item.Supplier === 'TJK') {
							dispatch(fareRuleTripJackSuccess(null));
							dispatch(FlightFareRuleTripJackDispatch(item.totalPriceList.id));
						}
					}}
					display='flex'
					justifyContent='space-between'>
					{/* <Box>
																		<BodyText
																			two
																			textStyle={{
																				fontFamily: 'Roboto Serif, sans-serif',
																				fontWeight: 700,
																			}}>
																			Free Meals
																		</BodyText>
																	</Box> */}
					<Box></Box>
					<Box
						display='flex'
						alignItems={'center'}
						style={{ cursor: 'pointer' }}>
						<p
							textStyle={{
								fontFamily: 'Roboto Serif, sans-serif',
								fontSize: 10,
							}}>
							Flight Details
						</p>
						<ArrowDropDownIcon />
					</Box>
				</Box>
			</Box>
			<Box>
				<span
					style={{ fontSize: 10, color: 'rgb(243 111 17)', fontWeight: 600 }}>
					{item.AirlineCode
						? ''
						: item.Airline_Code
						? item.Fares.Warning === null || item.Fares.Warning === ''
							? ''
							: 'SPECIAL FARE. IT MAY TAKE 20-30 MINS FOR ISSUANCE. ISSUANCE DEPENDS UPON AVAILABILITY. PASSENGER NAME WILL BE UPDATED 12 TO 24 HOURS PRIOR TO DEPARTURE ON AIRLINE WEBSITE'
						: ''}
				</span>
			</Box>
		</>
	);
};

export default OneWayDesktopRow;
