import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import BodyText from "./BodyText";
import TitleText from "./TitleText";
import SuccessModal from "./SuccessModal";
import { toggleFlightDetailModal } from "../../reducers/UiReducer";
import {
  agentloginDispatch,
  agentlogoDispatch,
} from "../../reducers/UserReducer";
import ErrorModal from "./ErrorModal";
import PassengerDetailRow from "./PassengerDetailRow";
import FlightSegment from "./FlightSegment";
const tableHeaders = [
  "Type",
  "Passenger Name",
  "Date of Birth",
  "Passport No",
  "Ticket No",
];
const tableHeaders2 = [
  "AirLine Name",
  "Flight No",
  "Origin",
  "Destination",
  "Depart Date & Time",
  "Arrival Date & Time",
];

const StyledTableCell = withStyles((theme) => ({
  head: {
    // fontWeight: 600,

    // padding: 3,
    fontSize: 12,
    textTransform: "uppercase",
  },
  root: {
    borderBottom: "none",
    paddingBottom: 0,
  },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    backdropFilter: "blur(2px)",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(2.5),
    },
    color: theme.palette.grey[500],
  },
  nextButton: {
    backgroundColor: "#E61855",
    color: "white",
    borderColor: "#E61855",
    borderRadius: 15,
    fontWeight: 600,
    width: "100%",
    padding: 10,
    "&:hover": {
      backgroundColor: "#E61855",
    },
  },
}));

const FlightModalDetail = ({ title }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { flightdetail, bookingHistoryID } = useSelector((state) => state.ui);
  const { bookinghistory, bookinghistoryagent, agentlogin } = useSelector(
    (state) => state.user
  );
  const [upload, setUpload] = useState(null);
  const [user, setUser] = useState({
    filename: "",
  });
  const { filename } = user;
  const onInputChange = (e) => {
    // setUser({ ...user, [e.target.name]: e.target.value });
    const fileToUpload = e.target.files;
    // setUser({ ...user, [e.target.name]: fileToUpload[0] });
    // console.log(e.target);
    setUpload(fileToUpload[0]);
  };
  let bookdata = [];
  if (bookinghistoryagent && bookingHistoryID) {
    bookinghistoryagent &&
      bookinghistoryagent.result &&
      bookinghistoryagent.result.flights.length > 0 &&
      bookinghistoryagent.result.flights.map((item) => {
        if (item.id === bookingHistoryID) {
          bookdata.push({
            ...item,
          });
        }
      });
  }
  console.log("Book",bookdata)
  // const parseData = JSON.parse(bookdata[0]?.bookingDetails)
  // console.log("Book2",parseData);
  const handleClose = () => {
    dispatch(toggleFlightDetailModal(false));
    // dispatch(setSuccessMessage(''));
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    // var dat = user;
    // var formData = JSON.stringify(dat);
    // console.log(formData);
    // let formData2 = {
    // 	agent_id: 1,
    // 	agentLogo: dat,
    // };
    // console.log(e);
    let formUpdate = new FormData();
    formUpdate.append("agent_id", 1);
    formUpdate.append("agentLogo", upload);
    dispatch(agentlogoDispatch(formUpdate));
  };
  const theme = useTheme();
  const fileInputRef = React.createRef();
  const matches = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 20,
          maxWidth: 1020,
        },
      }}
      TransitionProps={{
        style: { backgroundColor: "transparent" },
      }}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className={classes.root}
      open={flightdetail}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogContent
        style={{
          paddingBottom: 20,
        }}
      >
        <h3
          three={matches ? false : true}
          style={{ fontWeight: 600, textAlign: "center" }}
        >
          Flight Details
        </h3>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h6>Order Id</h6>
            <h6>{bookdata[0]?.orderId}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>PNR</h6>
            <h6>{bookdata[0]?.bookingRefrence}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Booking Status</h6>
            <h6>{bookdata[0]?.bookingStatus}</h6>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h6>Booking Id</h6>
            <h6>{bookdata[0]?.bookingId}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Fare Type</h6>
            <h6>{bookdata[0]?.fareType}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Ticket Cost</h6>
            <h6>INR {bookdata[0]?.ticketCost}</h6>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h6>Flight Depart From</h6>
            <h6>{bookdata[0]?.origin}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Booking Date</h6>
            <h6>{bookdata[0]?.dob}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Payable Amount</h6>
            <h6>INR {bookdata[0]?.payableAmount}</h6>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <h6>Flight Land To</h6>
            <h6>{bookdata[0]?.destination}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Journey Date</h6>
            <h6>{bookdata[0]?.travelDate}</h6>
          </Grid>
          <Grid item xs={4}>
            <h6>Payment Mode</h6>
            <h6>{bookdata[0]?.paymentMode}</h6>
          </Grid>
        </Grid>
        <Divider />
        <h4>Passenger Details</h4>
        <TableContainer style={{ marginTop: 15 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((title, index) => (
                  <StyledTableCell key={title}>{title}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <PassengerDetailRow parseData={bookdata[0]?JSON.parse(bookdata[0].selectedPax):null}/>
            </TableBody>
          </Table>
        </TableContainer>
        <h4>Flight Segments</h4>
        <TableContainer style={{ marginTop: 15 }}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders2.map((title, index) => (
                  <StyledTableCell key={title}>{title}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <FlightSegment  parseData={bookdata[0]?JSON.parse(bookdata[0].selectedFlight):null}/>
          </Table>
        </TableContainer>
        <Grid container style={{ justifyContent: "center" }}>
          <Box
            textAlign="center"
            style={{ cursor: "pointer" }}
            mt={2}
            onClick={() => dispatch(toggleFlightDetailModal(false))}
          >
            <BodyText textStyle={{ color: "#E61855", fontWeight: 600 }}>
              Close
              <Divider style={{ backgroundColor: "#E61855" }} />
            </BodyText>
          </Box>
        </Grid>
        <SuccessModal />
        <ErrorModal />
      </DialogContent>
    </Dialog>
  );
};

export default FlightModalDetail;
