import styles from './showseat.module.css';
import Drawer from '@material-ui/core/Drawer';
import {
	Box,
	Container,
	Grid,
	makeStyles,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Slider,
	withStyles,
	// FormControlLabel,
	Checkbox,
	Hidden,
	useMediaQuery,
	useTheme,
	Divider,
} from '@material-ui/core';
import React from 'react';

import { useState, useEffect } from 'react';
import SeatIcon from '../../assets/images/seatIcon.png';
import SeatBlue from '../../assets/images/seatblue.png';
import blue from '../../assets/images/blue.png';
import BusBookingForm from '../../form/BusBookingForm';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BoardingDropingForm from '../../form/BoardingDropingForm';

// //implementing sidebar

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiChip-sizeSmall': {
			height: 36,
		},
	},
	accodin: {
		'& .MuiAccordionSummary-content': {
			justifyContent: 'space-evenly',
		},
	},
	searhform: {
		// backgroundImage: `url(${Home})`,
		background: 'rgb(34, 118, 227)',
		// height: 272,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',

		paddingTop: 66,

		[theme.breakpoints.down('xs')]: {
			height: 590,
		},
	},
	radio: {
		'& .MuiTypography-body1': {
			fontSize: 13,
		},
	},
	scroll: {
		'&::-webkit-scrollbar': {
			width: '0.2em',
		},
		'&::-webkit-scrollbar-track': {
			// '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'rgba(0,0,0,.1)',
			outline: '1px solid slategrey',
			// borderTopLeftRadius: 5,
			// borderTopRightRadius: 5,
			borderRadius: 5,
		},
	},
}));

export const Showseat = ({ seatLayout, item }) => {
	//implement siderbar

	const [formDrawerState, setformDrawerState] = React.useState(false);
	const { layoutloading } = useSelector((state) => state.bus);
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const toggleDrawer = (open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'shift')
		) {
			return;
		}
		setformDrawerState(open);
	};
	let seater = [];
	let horizontalSleeper = [];
	let verticalSleeper = [];
	if (layoutloading) {
		console.log('loading.........');
	} else if (
		seatLayout &&
		seatLayout.result &&
		seatLayout?.result?.seats?.length > 0
	) {
		seatLayout &&
			seatLayout.result &&
			seatLayout.result.seats.length > 0 &&
			seatLayout.result.seats.map((item, index) => {
				if (item['length'] === '1' && item.width === '1') {
					seater.push({
						...item,
					});
				}
				if (item['length'] === '2' && item.width === '1') {
					horizontalSleeper.push({
						...item,
					});
				}
				if (item['length'] === '1' && item.width === '2') {
					verticalSleeper.push({
						...item,
					});
				}
				//   console.log("first",item['length'])
			});
	}
	let seatfilterrow0 = [];
	let seatfilterrow1 = [];
	let seatfilterrow2 = [];
	let seatfilterrow3 = [];
	let seatfilterrow4 = [];
	let seatfilterrow5 = [];
	let seatfilterrow6 = [];

	if (layoutloading) {
		console.log('loading.........');
	} else if (seater && seater.length > 0) {
		seater &&
			seater.length > 0 &&
			seater.map((item, index) => {
				if (item.row === '0') {
					seatfilterrow0.push({
						...item,
					});
				}
				if (item.row === '1') {
					seatfilterrow1.push({
						...item,
					});
				}
				if (item.row === '2') {
					seatfilterrow2.push({
						...item,
					});
				}
				if (item.row === '3') {
					seatfilterrow3.push({
						...item,
					});
				}
				if (item.row === '4') {
					seatfilterrow4.push({
						...item,
					});
				}
				if (item.row === '5') {
					seatfilterrow5.push({
						...item,
					});
				}
				if (item.row === '6') {
					seatfilterrow6.push({
						...item,
					});
				}

				//   console.log("first",item['length'])
			});
	}
	let filter = [];
	if (seatfilterrow0) {
		filter = seatfilterrow0.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let filter1 = [];
	if (seatfilterrow1) {
		filter1 = seatfilterrow1.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let filter2 = [];
	if (seatfilterrow2) {
		filter2 = seatfilterrow2.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let filter3 = [];
	if (seatfilterrow3) {
		filter3 = seatfilterrow3.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let filter4 = [];
	if (seatfilterrow4) {
		filter4 = seatfilterrow4.sort((a, b) => {
			return a.column - b.column;
		});
	}
	let filter5 = [];
	if (seatfilterrow5) {
		filter5 = seatfilterrow5.sort((a, b) => {
			return a.column - b.column;
		});
	}

	console.log('seater', seater);
	console.log('horizontalSleeper', horizontalSleeper);
	console.log('verticalSleeper', verticalSleeper);
	console.log('seatfilterrow1', seatfilterrow1);
	console.log('filter', filter);
	console.log('filter1', filter1);
	console.log('filter2', filter2);
	console.log('filter3', filter3);
	console.log('filter4', filter4);
	console.log('filter5', filter5);
	console.log('seatfilterrow2', seatfilterrow2);

	let seatsleeperHrow0 = [];
	let seatsleeperHrow1 = [];
	let seatsleeperHrow2 = [];
	let seatsleeperHrow3 = [];
	let seatsleeperHrow4 = [];
	let seatsleeperHrow5 = [];
	let seatsleeperHrow6 = [];

	if (layoutloading) {
		console.log('loading.........');
	} else if (horizontalSleeper && horizontalSleeper.length > 0) {
		horizontalSleeper &&
			horizontalSleeper.length > 0 &&
			horizontalSleeper.map((item, index) => {
				if (item.row === '0') {
					seatsleeperHrow0.push({
						...item,
					});
				}
				if (item.row === '1') {
					seatsleeperHrow1.push({
						...item,
					});
				}
				if (item.row === '2') {
					seatsleeperHrow2.push({
						...item,
					});
				}
				if (item.row === '3') {
					seatsleeperHrow3.push({
						...item,
					});
				}
				if (item.row === '4') {
					seatsleeperHrow4.push({
						...item,
					});
				}
				if (item.row === '5') {
					seatsleeperHrow5.push({
						...item,
					});
				}
				if (item.row === '6') {
					seatsleeperHrow6.push({
						...item,
					});
				}

				//   console.log("first",item['length'])
			});
	}
	console.log('seatsleeperHrow0', seatsleeperHrow0);
	console.log('seatsleeperHrow1', seatsleeperHrow1);
	console.log('seatsleeperHrow2', seatsleeperHrow2);
	console.log('seatsleeperHrow3', seatsleeperHrow3);
	console.log('seatsleeperHrow4', seatsleeperHrow4);
	console.log('seatsleeperHrow5', seatsleeperHrow5);
	console.log('seatsleeperHrow6', seatsleeperHrow6);
	const [selectedSeat, setSelectedSeat] = useState({});
	const [condition, setCondition] = useState(false);
	const [seat, setSeat] = useState();

	//   console.log("selectedSeat", selectedSeat);
	let selectSeat = [];
	if (seat && seat.length > 0 && seatLayout) {
		seatLayout &&
			seatLayout.result &&
			seatLayout?.result?.seats?.length > 0 &&
			seatLayout?.result?.seats?.map((i) => {
				seat.map((it) => {
					if (it === i.name) {
						selectSeat.push({
							...i,
						});
					}
				});
			});
	}
	console.log('selectSeat', selectSeat);
	const handleChange = (e) => {
		let { name, checked, type } = e.target;
		console.log('e', e);
		if (selectSeat?.length <= 5) {
			setSelectedSeat({
				...selectedSeat,
				[name]: (type = 'checkbox' ? checked : ''),
			});
		} else {
			window.alert('Hello');
		}
	};
	useEffect(() => {
		for (let x in selectedSeat) {
			if (selectedSeat[x] === false) {
				setCondition(false);
				continue;
			} else {
				setCondition(true);
				break;
			}
		}

		const a = [];
		for (let y in selectedSeat) {
			if (selectedSeat[y] == false) {
				continue;
			} else {
				a.push(y);
			}
		}

		setSeat(a);
	}, [selectedSeat]);

	return (
		<>
			<div
				style={{
					marginTop: 40,
					width: matches?'100%':'70%',
					// overflowX: 'auto',
					// overflowY: 'hidden',
				}}>
				<div className={styles.label}>
					<span className={styles.lblmsg}>
						Click on an Available seat to proceed with your transaction.
					</span>
				</div>

				<div
					style={{
						// overflowY: 'scroll',
						// height: '250px',
						width: '100%',
						scrollbarWidth: 'thin',
						marginTop: 10,
					}}
					className={classes.scroll}>
						<div style={{border:'1px solid grey',padding:10,display:matches?'flex':'', overflowX: 'auto'}}>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':''
						}}>
						{seatsleeperHrow0 &&
							seatsleeperHrow0.length > 0 &&
							seatsleeperHrow0.map((i, index) => {
								return (
									<div className='sleeper'>
										{/* <span style={{position:'absolute',marginLeft:2,color:'brown'}}>{i.name}</span> */}
										{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
										<input
											className='myDIV'
											onChange={handleChange}
											// onClick={()=>console.log("hello")}
											// onClick={()=>dispatch(setSelectedSeat(i))}
											onMouseEnter={() => console.log('first')}
											name={i.name}
											type='checkbox'
											value={'sleeper'}
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':''
						}}>
						{seatsleeperHrow1 && seatsleeperHrow1.length > 0
							? seatsleeperHrow1 &&
							  seatsleeperHrow1.length > 0 &&
							  seatsleeperHrow1.map((i, index) => {
									return (
										<div className='sleeper'>
											{/* <span style={{position:'absolute',marginLeft:2,color:'brown'}}>{i.name}</span> */}
											{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
											<input
												className='myDIV'
												onChange={handleChange}
												onMouseEnter={() => console.log('first')}
												name={i.name}
												type='checkbox'
												value={'sleeper'}
												id={`checkbox-id${i.name}`}
												style={{ color: 'red' }}
												disabled={i.available === 'true' ? false : true}
											/>{' '}
											<label for={`checkbox-id${i.name}`}></label>
										</div>
									);
							  })
							: ''}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':''
						}}>
						{seatsleeperHrow2 && seatsleeperHrow2.length > 0
							? seatsleeperHrow2 &&
							  seatsleeperHrow2.length > 0 &&
							  seatsleeperHrow2.map((i, index) => {
									return (
										<div className='sleeper'>
											{/* <span style={{position:'absolute',marginLeft:2,color:'brown'}}>{i.name}</span> */}
											{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
											<input
												className='myDIV'
												onChange={handleChange}
												onMouseEnter={() => console.log('first')}
												name={i.name}
												type='checkbox'
												value={'sleeper'}
												id={`checkbox-id${i.name}`}
												style={{ color: 'red' }}
												disabled={i.available === 'true' ? false : true}
											/>{' '}
											<label for={`checkbox-id${i.name}`}></label>
										</div>
									);
							  })
							: ''}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':''
						}}>
						{seatsleeperHrow3 && seatsleeperHrow3.length > 0
							? seatsleeperHrow3 &&
							  seatsleeperHrow3.length > 0 &&
							  seatsleeperHrow3.map((i, index) => {
									return (
										<div className='sleeper'>
											{/* <span style={{position:'absolute',marginLeft:2,color:'brown'}}>{i.name}</span> */}
											{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
											<input
												className='myDIV'
												onChange={handleChange}
												onMouseEnter={() => console.log('first')}
												name={i.name}
												type='checkbox'
												value={'sleeper'}
												id={`checkbox-id${i.name}`}
												style={{ color: 'red' }}
												disabled={i.available === 'true' ? false : true}
											/>{' '}
											<label for={`checkbox-id${i.name}`}></label>
										</div>
									);
							  })
							: ''}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':''
						}}>
						{seatsleeperHrow4 && seatsleeperHrow4.length > 0
							? seatsleeperHrow4 &&
							  seatsleeperHrow4.length > 0 &&
							  seatsleeperHrow4.map((i, index) => {
									return (
										<div className='sleeper'>
											{/* <span style={{position:'absolute',marginLeft:2,color:'brown'}}>{i.name}</span> */}
											{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
											<input
												className='myDIV'
												onChange={handleChange}
												onMouseEnter={() => console.log('first')}
												name={i.name}
												type='checkbox'
												value={'sleeper'}
												id={`checkbox-id${i.name}`}
												style={{ color: 'red' }}
												disabled={i.available === 'true' ? false : true}
											/>{' '}
											<label for={`checkbox-id${i.name}`}></label>
										</div>
									);
							  })
							: ''}
					</div>
					</div>
					<div style={{border:'1px solid grey',padding:10,display:matches?'flex':'',marginTop:20,overflowX:'auto'}}>
					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':'',
							marginTop: 10,
						}}>
						{filter && filter.length > 0 ? (
							filter &&
							filter.length > 0 &&
							filter.map((i, index) => {
								return (
									<div>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										{/* <span className="hide" style={{position:'absolute',marginLeft:2,color:'brown',background:'yellow',padding:5}}>{i.fare}</span> */}
										<input
											// className="myDIV"
											onChange={handleChange}
											onMouseEnter={() => console.log('first')}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					<div style={{ display: 'flex',flexDirection:matches?'column':'' }}>
						{filter1 && filter1.length > 0 ? (
							filter1 &&
							filter1.length > 0 &&
							filter1.map((i, index) => {
								return (
									<div style={{ paddingTop: '10px' }}>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										<input
											onChange={handleChange}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>

					<div
						style={{
							display: 'flex',
							flexDirection:matches?'column':'',
							justifyContent: filter2 && filter2.length === 1 ? 'end' : '',
						}}>
						{filter2 && filter2.length > 0 ? (
							filter2 &&
							filter2.length > 0 &&
							filter2.map((i, index) => {
								return (
									<div style={{ paddingTop: '10px' }}>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										<input
											onChange={handleChange}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					<div style={{ display: 'flex',flexDirection:matches?'column':'' }}>
						{filter3 && filter3.length > 0 ? (
							filter3 &&
							filter3.length > 0 &&
							filter3.map((i, index) => {
								return (
									<div style={{ paddingTop: '10px' }}>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										<input
											onChange={handleChange}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>

					<div style={{ display: 'flex',flexDirection:matches?'column':'' }}>
						{filter4 && filter4.length > 0 ? (
							filter4 &&
							filter4.length > 0 &&
							filter4.map((i, index) => {
								return (
									<div style={{ paddingTop: 10 }}>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										<input
											onChange={handleChange}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					<div style={{ display: 'flex',flexDirection:matches?'column':'' }}>
						{filter5 && filter5.length > 0 ? (
							filter5 &&
							filter5.length > 0 &&
							filter5.map((i, index) => {
								return (
									<div style={{ paddingTop: '20px' }}>
										<span
											style={{
												position: 'absolute',
												marginLeft: -3,
												color: 'brown',
												fontSize: 12,
											}}>
											{i.name}
										</span>
										<input
											onChange={handleChange}
											name={i.name}
											type='checkbox'
											id={`checkbox-id${i.name}`}
											style={{ color: 'red' }}
											disabled={i.available === 'true' ? false : true}
										/>{' '}
										<label for={`checkbox-id${i.name}`}></label>
									</div>
								);
							})
						) : (
							<></>
						)}
					</div>
					</div>
				</div>

				<div
					className='seat'
					style={{
						marginTop: 20,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						flexDirection:matches?'column':''
					}}>
					<Box display={'flex'}>
						<h3>Seat No.</h3>
						<div style={{ display: 'flex' }}>
							{seat?.length !== 0 ? (
								<div
									style={{ display: 'flex', marginLeft: '5px', marginTop: 5 }}>
									{seat?.map((e) => (
										<h5 style={{ marginLeft: '10px' }}>{e}</h5>
									))}
								</div>
							) : (
								''
							)}
						</div>
					</Box>
					<Box>
						<span style={{ fontSize: 20 }}>
							Total Amount: ₹{' '}
							{selectSeat &&
								selectSeat.length > 0 &&
								selectSeat
									?.map((i) => parseInt(i.fare))
									.reduce((prev, next) => prev + next)}
						</span>
					</Box>
				</div>
				<BoardingDropingForm item={item} />
				<Box
					onClick={toggleDrawer(true)}
					style={{
						background: 'rgb(0, 55, 114)',
						padding: '10px',
						width: '50%',
						textAlign: 'center',
						color: 'white',
						borderRadius: '5px',
						fontSize: '14px',
						cursor: 'pointer',
						marginTop: 20,
					}}>
					<span>PROCCED TO BOOK</span>
				</Box>
				<div>
					<Drawer
						anchor='right'
						open={formDrawerState}
						onClose={toggleDrawer(false)}>
						<div style={{ width: '700px' }}>
							<Box textAlign={'center'}>
								<h2>Passenger Details</h2>
							</Box>
							<div
								style={{ padding: 20, overflowY: 'scroll', height: '550px' }}>
								<Box
									display='flex'
									alignItems='center'
									style={{ marginLeft: 10 }}>
									<i class='fa fa-user' aria-hidden='true'></i>
									<h4 style={{ marginLeft: 10 }}>Passenger Information</h4>
								</Box>
								<Divider />

								<BusBookingForm selectSeat={selectSeat} item={item} />
							</div>
							<Box
								display='flex'
								justifyContent='space-between'
								style={{ padding: 20 }}>
								<Box>
									<span style={{ fontSize: 20 }}>
										Total Amount: ₹{' '}
										{selectSeat &&
											selectSeat.length > 0 &&
											selectSeat
												?.map((i) => parseInt(i.fare))
												.reduce((prev, next) => prev + next)}
									</span>
								</Box>
								{/* <Box
                  onClick={() => history.push("/buspaymentdetails")}
                  style={{
                    background: "rgb(57, 201, 187)",
                    padding: 5,
                    color: "white",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                >
                  <span>PROCCED TO PAY</span>
                </Box> */}
							</Box>
						</div>
					</Drawer>
				</div>
			</div>
			{matches?'':
			<div style={{ marginTop: 50, marginLeft: 10 }}>
				<Box style={{ border: '1px solid red', padding: 10 }}>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img src={SeatIcon} style={{ height: 20 }} />
						<span style={{ marginLeft: 5, fontSize: 12 }}>Available Seat</span>
					</Box>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img src={blue} style={{ height: 20 }} />
						<span style={{ marginLeft: 5, fontSize: 12 }}>Booked Seat</span>
					</Box>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img src={SeatBlue} style={{ height: 20 }} />
						<span style={{ marginLeft: 5, fontSize: 12 }}>Selected Seat</span>
					</Box>
				</Box>
				<Box style={{ border: '1px solid red', padding: 10 }}>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img
							src={
								'https://2021.musafirbazar.com/bus/images/available_sleeper.jpg'
							}
							style={{ height: 15 }}
						/>
						<span style={{ marginLeft: 5, fontSize: 12 }}>
							Available Sleeper Seat
						</span>
					</Box>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img
							src={
								'https://2021.musafirbazar.com/bus/images/booked_sleeper.jpg'
							}
							style={{ height: 15 }}
						/>
						<span style={{ marginLeft: 5, fontSize: 12 }}>
							Booked Sleeper Seat
						</span>
					</Box>
					<Box
						display='flex'
						justifyContent='space-between'
						alignItems='center'>
						<img
							src={
								'https://2021.musafirbazar.com//bus/images/selected_sleeper.jpg'
							}
							style={{ height: 15 }}
						/>
						<span style={{ marginLeft: 5, fontSize: 12 }}>
							Selected Sleeper Seat
						</span>
					</Box>
				</Box>
			</div>
}
		</>
	);
};
