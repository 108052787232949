import React, { useEffect } from 'react';
import { Box, withStyles, TableCell } from '@material-ui/core';
import { agenttransactionhistoryDispatch } from '../../reducers/UserReducer';
import { setSiderBar } from '../../reducers/UiReducer';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
const StyledTableCell = withStyles((theme) => ({
	head: {
		fontWeight: 600,
		color: '#8C8C8C',
		padding: 3,
		fontSize: 18,
	},
	root: {
		borderBottom: 'none',
		paddingBottom: 0,
	},
}))(TableCell);

const Dashboard = () => {
	const {
		wallet,
		walletagent,
		transagent,
		bookinghistoryagent,
		loadinguser,
		agentlogin,
	} = useSelector((state) => state.user);
	const history = useHistory();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			agenttransactionhistoryDispatch(agentlogin && agentlogin.result.result.id)
		);
	}, []);
	let tranData = [];
	let tranData2 = [];
	if (loadinguser) {
		console.log('first');
	} else {
		tranData =
			transagent &&
			transagent.result &&
			transagent.result.result?.length > 0 &&
			transagent.result.result?.filter((person) => person.type === 'CR');

		tranData2 =
			transagent &&
			transagent.result &&
			transagent.result.result?.length > 0 &&
			transagent.result.result?.filter((person) => person.type === 'DR');
	}
	console.log('tranData', tranData);

	const tableHeaders = [
		'ORDER_ID',
		'DEPART FROM',
		'JOURNEY TO',
		'DATE OF BOOKING',
		'JOURNEY DATE',
		'TICKET COST',
		'STATUS',
		// 'ACTION',
	];

	return (
		<>
			<div className='container-fluid px-4'>
				<div className='row g-3 my-2'>
					<div className='col-md-3'>
						<div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded'>
							<div>
								<h6 style={{ fontWeight: 'bold' }}>
									No.{' '}
									{bookinghistoryagent &&
										bookinghistoryagent?.result?.flights?.length}
								</h6>
								<p className='fs-5'>Booked</p>
							</div>
							<i className='fa fa-ticket fs-1 primary-text border rounded-full secondary-bg p-3'></i>
						</div>
					</div>

					<div className='col-md-3'>
						<div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded'>
							<div>
								<h6 style={{ fontWeight: 'bold' }}>No. {tranData?.length}</h6>
								<p className='fs-5'>CR Transaction</p>
							</div>
							<i className='fas fa-hand-holding-usd fs-1 primary-text border rounded-full secondary-bg p-3'></i>
						</div>
					</div>

					<div className='col-md-3'>
						<div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded'>
							<div>
								<h6 style={{ fontWeight: 'bold' }}>No. {tranData2?.length}</h6>
								<p className='fs-5'>DR Transaction</p>
							</div>
							<i
								className='fas fa-hand-holding-usd fs-1 primary-text border rounded-full secondary-bg p-3'
								style={{ color: 'white', backgroundColor: 'red' }}></i>
						</div>
					</div>

					<div className='col-md-3'>
						<div className='p-3 bg-white shadow-sm d-flex justify-content-around align-items-center rounded'>
							<div>
								<h6 style={{ fontWeight: 'bold' }}>
									₹ {walletagent && walletagent?.result?.balanceAmount}
								</h6>
								<p className='fs-5'>Balance</p>
							</div>
							<i className='fa fa-money fs-1 primary-text border rounded-full secondary-bg p-3'></i>
						</div>
					</div>
				</div>

				<div className='row my-5'>
					<Box display='flex' justifyContent='space-between'>
						<h3 className='fs-5 mb-3'>Recent Booking</h3>
						<span
							style={{ cursor: 'pointer', color: 'blue' }}
							onClick={() => {
								dispatch(setSiderBar('book'));
								history.push({
									pathname: '/bookhistory',
									state: {
										title: 'Booking History',
									},
								});
							}}>
							See All
						</span>
					</Box>

					<div className='col'>
						<table className='table bg-white rounded shadow-sm  table-hover'>
							<thead>
								<tr>
									<th scope='col' width='50'>
										#
									</th>
									{tableHeaders.map((i) => {
										return (
											<th scope='col' style={{ fontFamily: 'monospace' }}>
												{i}
											</th>
										);
									})}
								</tr>
							</thead>
							<tbody>
								{bookinghistoryagent &&
									bookinghistoryagent?.result?.flights?.length > 0 &&
									bookinghistoryagent?.result?.flights.map((i, index) => {
										return index < 10 ? (
											<tr>
												<th
													scope='row'
													style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{index + 1}
												</th>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.orderId}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.origin}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.destination}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.dob}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.travelDate}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.ticketCost}
												</td>
												<td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													{i.bookingStatus}
												</td>
												{/* <td style={{ fontSize: 14, fontFamily: 'Poppins' }}>
													<i
														class='fa-solid fa-eye'
														style={{ cursor: 'pointer' }}
														onClick={() => {
															// dispatch(toggleFlightDetailModal(true));
															// dispatch(setBookingHistoryID(item.id));
														}}></i>
													<i
														class='fa-solid fa-envelope'
														style={{ marginLeft: 5, cursor: 'pointer' }}
														// onClick={handleMail}
													></i>
													<i
														class='fa-solid fa-print'
														// onClick={() =>
														// 	history.push({
														// 		pathname: '/ticketdisplay',
														// 		state: item,
														// 	})
														// }
														style={{ marginLeft: 5, cursor: 'pointer' }}></i>
												</td> */}
											</tr>
										) : (
											''
										);
									})}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
