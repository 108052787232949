import axios from 'axios';
import { axiosInstance } from '../Axios';
import { storeData, toggleEasebuzzModal } from './UiReducer';
const { createSlice } = require('@reduxjs/toolkit');

const HotelReducer = createSlice({
	name: 'Hotel',
	initialState: {
		success: false,
		error: null,
		orderID: null,
		loadinghotel: false,
		loadinghoteldes: false,
		hotelresult: sessionStorage.getItem('hotelresult')
			? JSON.parse(sessionStorage.getItem('hotelresult'))
			: null,
		hotelinfo: sessionStorage.getItem('hotelinfo')
			? JSON.parse(sessionStorage.getItem('hotelinfo'))
			: null,
		hotelroominfo: sessionStorage.getItem('hotelroominfo')
			? JSON.parse(sessionStorage.getItem('hotelroominfo'))
			: null,
		hotelroomblock: null,
		hotelbook: null,
		hotelbookdetail: sessionStorage.getItem('hotelbookdetail')
			? JSON.parse(sessionStorage.getItem('hotelbookdetail'))
			: null,
		addBooking: null,
		debit: null,
	},
	reducers: {
		cityHotelRequest(state, action) {
			// state.loadinghotel = true;
		},
		cityHotelSuccess(state, action) {
			// state.loadinghotel = false;
			state.hotelcity = action.payload;
			storeData('hotelcity', action.payload);
			state.error = null;
		},
		cityHotelFail(state, action) {
			// state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelSearchRequest(state, action) {
			state.loadinghotel = true;
		},
		hotelSearchSuccess(state, action) {
			state.loadinghotel = false;
			state.hotelresult = action.payload;
			storeData('hotelresult', action.payload);
			state.error = null;
		},
		hotelSearchFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		addhotelRequest(state, action) {
			state.loadinghotel = true;
		},
		addhotelSuccess(state, action) {
			// state.loadinghotel = false;
			state.orderID = action.payload;
			state.error = null;
		},
		addhotelFail(state, action) {
			state.loadinghotel = false;
			state.error = action.payload;
		},
		hotelinfoRequest(state, action) {
			state.loadinghoteldes = true;
		},
		hotelinfoSuccess(state, action) {
			// state.loadinghotel = false;
			state.hotelinfo = action.payload;
			storeData('hotelinfo', action.payload);
			state.error = null;
		},
		hotelinfoFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		hotelroominfoRequest(state, action) {
			state.loadinghoteldes = true;
		},
		hotelroominfoSuccess(state, action) {
			state.loadinghoteldes = false;
			state.hotelroominfo = action.payload;
			storeData('hotelroominfo', action.payload);
			state.error = null;
		},
		hotelroominfoFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		hotelroomblockRequest(state, action) {
			state.loadinghoteldes = true;
		},
		hotelroomblockSuccess(state, action) {
			state.loadinghoteldes = false;
			state.hotelroomblock = action.payload;
			state.error = null;
		},
		hotelroomblockFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		hotelbookRequest(state, action) {
			state.loadinghoteldes = true;
		},
		hotelbookSuccess(state, action) {
			state.loadinghoteldes = false;
			state.hotelbook = action.payload;
			state.error = null;
		},
		hotelbookFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		hotelbookdetailRequest(state, action) {
			state.loadinghoteldes = true;
		},
		hotelbookdetailSuccess(state, action) {
			state.loadinghoteldes = false;
			state.hotelbookdetail = action.payload;
			storeData('hotelbookdetail', action.payload);
			state.error = null;
		},
		hotelbookdetailFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		addBookingRequest(state, action) {
			state.loadinghoteldes = true;
		},
		addBookingSuccess(state, action) {
			state.loadinghoteldes = false;
			state.addBooking = action.payload;
			state.error = null;
		},
		addBookingFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
		debitRequest(state, action) {
			state.loadinghoteldes = true;
		},
		debitSuccess(state, action) {
			state.loadinghoteldes = false;
			state.debit = action.payload;
			state.error = null;
		},
		debitFail(state, action) {
			state.loadinghoteldes = false;
			state.error = action.payload;
		},
	},
});
const config = {
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Client-Service': 'srk-client',
		'Auth-Key': 'srkapi',
		'Api-Key': 'NjO5hqe3PG9qZFfizz==',
	},
};
const { actions } = HotelReducer;

export const {
	cityHotelRequest,
	cityHotelSuccess,
	cityHotelFail,
	hotelSearchRequest,
	hotelSearchSuccess,
	hotelSearchFail,
	addhotelRequest,
	addhotelSuccess,
	addhotelFail,
	hotelinfoRequest,
	hotelinfoSuccess,
	hotelinfoFail,
	hotelroominfoRequest,
	hotelroominfoSuccess,
	hotelroominfoFail,
	hotelroomblockRequest,
	hotelroomblockSuccess,
	hotelroomblockFail,
	hotelbookRequest,
	hotelbookSuccess,
	hotelbookFail,
	hotelbookdetailRequest,
	hotelbookdetailSuccess,
	hotelbookdetailFail,
	addBookingRequest,
	addBookingSuccess,
	addBookingFail,
	debitRequest,
	debitSuccess,
	debitFail,
} = actions;

export const fetchHotelCityDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(cityHotelRequest());
		const { data } = await axiosInstance.post(
			'home/hotelcity',
			bodyData,
			config
		);
		console.log(data);
		dispatch(cityHotelSuccess(data));
	} catch (error) {
		dispatch(
			cityHotelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelSearchDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(hotelSearchRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/search',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelSearchSuccess(data));

		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			hotelSearchFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const addhotelSearchDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelSearchSuccess(null));
		dispatch(addhotelRequest());
		const { data } = await axiosInstance.post(
			'hotel/addsearchhotel',
			bodyData,
			config
		);

		console.log(data);
		dispatch(addhotelSuccess(data));

		if (data.status === 200) {
			dispatch(hotelSearchDispatch(bodyData));
		}
	} catch (error) {
		dispatch(
			addhotelFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelInfoDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelinfoRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/hotelinfo',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelinfoSuccess(data));

		if (data.status === 200) {
			// history.push({
			// 	pathname: `hotellist/${HotelName}`,
			// });
		}
	} catch (error) {
		dispatch(
			hotelinfoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelRoomInfoDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(hotelroominfoRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/roominfo',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelroominfoSuccess(data));

		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			hotelroominfoFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelRoomBlockDispatch =
	(bodyData, payment, formDataBooking, history, debit) => async (dispatch) => {
		try {
			dispatch(hotelroomblockRequest());
			const { data } = await axiosInstance.post(
				'hoteltbo/blockroom',
				bodyData,
				config
			);

			console.log(data);
			dispatch(hotelroomblockSuccess(data));

			if (data.status === 200) {
				if (payment === 'easebuzz') {
					dispatch(toggleEasebuzzModal(true));
					dispatch(hoteldebitPaymentDispatch(debit));
				} else {
					dispatch(hotelBookDispatch(formDataBooking, history));
				}
			}
		} catch (error) {
			dispatch(
				hotelroomblockFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const hotelBookDispatch = (bodyData, history) => async (dispatch) => {
	try {
		dispatch(hotelbookRequest());
		const { data } = await axiosInstance.post(
			'hoteltbo/hotelbook',
			bodyData,
			config
		);

		console.log(data);
		dispatch(hotelbookSuccess(data));

		if (data.status === 200) {
			let formData = {
				bookingId: data.result.BookResult.BookingId,
				orderId: bodyData.orderId,
			};
			dispatch(hotelBookingDetailDispatch(formData, history));
			// if (payment === 'easebuzz') {
			// 	dispatch(toggleEasebuzzModal(true));
			// }
		}
	} catch (error) {
		dispatch(
			hotelbookFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export const hotelBookingDetailDispatch =
	(bodyData, history) => async (dispatch) => {
		try {
			dispatch(hotelbookdetailRequest());
			const { data } = await axiosInstance.post(
				'hoteltbo/bookingdetails',
				bodyData,
				config
			);

			console.log(data);
			dispatch(hotelbookdetailSuccess(data));

			if (data.status === 200) {
				// history.push({
				// 	pathname:'/hotelticket',
				// 	state:{bookingdetail:data.result.GetBookingDetailResult}
				// })
				history.push({
					pathname: '/hotelticket',
					state: { bookingdetailhotel: data.result.GetBookingDetailResult },
				});
			}
		} catch (error) {
			dispatch(
				hotelbookdetailFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const hoteladdBookingDispatch =
	(bodyData, formData, payment, formDataBooking, history, debit) =>
	async (dispatch) => {
		try {
			dispatch(addBookingRequest());
			const { data } = await axiosInstance.post(
				'hotel/addbookinghotel',
				bodyData,
				config
			);

			console.log(data);
			dispatch(addBookingSuccess(data));

			if (data.status === 200) {
				dispatch(
					hotelRoomBlockDispatch(
						formData,
						payment,
						formDataBooking,
						history,
						debit
					)
				);
			}
		} catch (error) {
			dispatch(
				addBookingFail(
					error.response && error.response.data.message
						? error.response.data.message
						: error.message
				)
			);
		}
	};
export const hoteldebitPaymentDispatch = (bodyData) => async (dispatch) => {
	try {
		dispatch(debitRequest());
		const { data } = await axiosInstance.post(
			'hotel/debithotelpayment',
			bodyData,
			config
		);

		console.log(data);
		dispatch(debitSuccess(data));

		if (data.status === 200) {
		}
	} catch (error) {
		dispatch(
			debitFail(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			)
		);
	}
};
export default HotelReducer;
