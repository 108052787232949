import React, { useEffect } from 'react';
import {
	makeStyles,
	Box,
	Grid,
	MenuItem,
	TextField as TextField2,
} from '@material-ui/core';
import { Field, Form, Formik, FieldArray } from 'formik';
import { TextField } from 'formik-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addBusBookingDispatch } from '../reducers/BusReducer';
const gender = ['Male', 'Female'];
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		// backgroundColor: 'white',
		marginBottom: 30,
		width: '100%',
		marginTop: 10,
		borderRadius: 5,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 15,
			marginTop: 8,
		},
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
		},
		'& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root': {
			borderRadius: 5,
		},
	},
	InputPropsStyle: {
		// backgroundColor: 'white',
		borderRadius: 15,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: 'rgb(0, 55, 114)',
		color: 'white',
		borderRadius: 12,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: 'rgb(0, 55, 114)',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	date: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 5,
			width: '100%',
		},
	},
	dob: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	passport: {
		'& .MuiFormControl-root': {
			width: '100%',
		},
	},
	root: {
		'& .MuiFormHelperText-root': {
			fontSize: 12,
		},
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
		// '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
		// 	fontSize: 14,
		// },
	},
	root2: {
		'& .MuiOutlinedInput-input': {
			fontSize: 14,
			padding: '13px 14px',
		},
		'& MuiInputLabel-root': {
			fontSize: 16,
		},
		'& label': {
			fontSize: 15,
		},
	},
}));
const BusBookingForm = ({ selectSeat, item }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { selectedTripID, boardingPointId, selectedBus, selectedDesBus } =
		useSelector((state) => state.ui);

	const { addBusSearch } = useSelector((state) => state.bus);
	const { ssrTBO } = useSelector((state) => state.home);
	function calculate_age(dob) {
		var diff_ms = Date.now() - dob.getTime();
		var age_dt = new Date(diff_ms);

		// return Math.abs(age_dt.getUTCFullYear() - 1970);
		let age = Math.abs(age_dt.getUTCFullYear() - 1970);
		if (age < 6) {
			alert('Please Select Valid Age');
		} else if (age > 12) {
			alert('Please Select Valid Age');
		}
		console.log('Hen', Math.abs(age_dt.getUTCFullYear() - 1970));
	}
	const dispatch = useDispatch();

	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	console.log('item', item);
	const handleFormSubmit = (data) => {
		// console.log("data", data);

		// dispatch(addBusBookingDispatch(formData));
		history.push({
			pathname: '/buspaymentdetails',
			state: {
				data: data,
				selectSeat: selectSeat,
				source: selectedBus,
				des: selectedDesBus,
				orderId: addBusSearch.result,
				item: item,
				selectedTripID: selectedTripID,
				boardingPointId: boardingPointId,
			},
		});
	};
	const Passenger = 4;

	const ar = [];

	for (let i = 1; i <= selectSeat?.length; i++) {
		ar.push({});
	}
	console.log('first', ar);

	return (
		<>
			<Formik
				initialValues={{
					Passengers: ar,
				}}
				onSubmit={handleFormSubmit}>
				{(formik) => (
					<Form>
						<Box
							className={classes.root}
							style={{
								background: 'white',
								padding: '10px 15px',
								border: '1px solid #e6e6e6',
								marginTop: '10px',
								boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
								borderRadius: '5px',
							}}>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 20, alignItems: 'baseline' }}>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='email'
										type='email'
										variant='outlined'
										// error={errors.email}
										label='Email ID'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<Field
										component={TextField}
										name='phone'
										type='number'
										variant='outlined'
										// error={errors.phone}
										label='Mobile No.'
										className={classes.selectField}
										InputProps={{
											className: classes.InputPropsStyle,
										}}
										inputProps={{
											style: { padding: 12 },
										}}
									/>
								</Grid>
							</Grid>
						</Box>
						<FieldArray
							name='Passenger'
							render={(arrayHelpers) => {
								return (
									<div>
										{formik.values.Passengers.map(
											(Passenger, index) =>
												selectSeat &&
												selectSeat.length > 0 &&
												selectSeat.map((i, indx) => {
													return indx === index ? (
														<>
															<Box
																style={{
																	boxShadow: '0 0 4px 0 hsl(0deg 0% 64% / 50%)',
																	padding: '1em 1em 0.5em',
																	background: '#fff',
																	marginBottom: '0.5em',
																	position: 'relative',
																	marginTop: 20,
																}}>
																<span>{`Passenger ${index + 1} | Seat ${
																	i.name
																} `}</span>
																<Grid
																	item
																	xs={12}
																	sm={6}
																	lg={12}
																	style={{ marginTop: 10 }}>
																	<Field
																		component={TextField}
																		name={`Passenger.${index}.name`}
																		type='text'
																		// error={errors.first_nameA1}
																		variant='outlined'
																		label='Name'
																		className={classes.selectField}
																		InputProps={{
																			className: classes.InputPropsStyle,
																		}}
																		inputProps={{
																			style: { padding: 12 },
																		}}
																	/>
																</Grid>
																<Grid
																	container
																	spacing={2}
																	alignItems='center'
																	style={{
																		marginTop: 20,
																		alignItems: 'baseline',
																	}}>
																	<Grid
																		item
																		xs={12}
																		sm={6}
																		lg={6}
																		className={classes.root2}
																		style={{ alignItems: 'center' }}>
																		<Field
																			component={TextField}
																			select
																			name={`Passenger.${index}.gender`}
																			type='text'
																			variant='outlined'
																			label='Select Gender'
																			// error={errors.genderA1}
																			className={classes.selectField}
																			InputProps={{
																				className: classes.InputPropsStyle,
																			}}
																			inputProps={{
																				className: classes.inputPropsStyle,
																			}}>
																			{gender.map((item, index) => (
																				<MenuItem key={index} value={item}>
																					{item}
																				</MenuItem>
																			))}
																		</Field>
																	</Grid>
																	<Grid item xs={12} sm={6} lg={6}>
																		<Field
																			component={TextField}
																			name={`Passenger.${index}.age`}
																			type='number'
																			// error={errors.first_nameA1}
																			variant='outlined'
																			label='Age'
																			className={classes.selectField}
																			InputProps={{
																				className: classes.InputPropsStyle,
																			}}
																			inputProps={{
																				style: { padding: 12 },
																			}}
																		/>
																	</Grid>
																</Grid>
															</Box>
														</>
													) : (
														''
													);
												})
										)}
									</div>
								);
							}}
						/>
						<Box
							display='flex'
							justifyContent='space-between'
							style={{ padding: 20 }}>
							<button
								type='submit'
								className={classes.createButton}
								style={{
									marginTop: 10,
									fontSize: 16,
									cursor: 'pointer',
									width: '50%',
									fontWeight: 'bold',
								}}>
								PROCCED TO PAY
							</button>
							{/* <span>PROCCED TO PAY</span> */}
							{/* </Box> */}
						</Box>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default BusBookingForm;
