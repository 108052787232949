import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import {
	makeStyles,
	Button,
	MenuItem,
	LinearProgress,
	Box,
	TextField,
	InputAdornment,
	Grid,
	Tabs,
	Tab,
	Divider,
	withStyles,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	FormControl,
} from '@material-ui/core';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { useDispatch } from 'react-redux';
// import InputAdornment from '@mui/material/InputAdornment';
import {
	setOrigin,
	setDepart,
	setOriginO,
	setDepartO,
} from '../../reducers/UiReducer';
const useStyles = makeStyles((theme) => ({
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid #959595',
			},
		},
		'& label': {
			display: 'none',
		},
		// backgroundColor: '#eee',
		// padding: 2.5,
		// marginBottom: 30,
		width: '100%',
		border:'1px solid lightgrey',
		// marginTop: 10,
		borderRadius: 4,
		// [theme.breakpoints.down('xs')]: {
		// 	marginBottom: 15,
		// 	marginTop: 8,
		// },
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover': {
			borderBottom: 'none',
		},
		'& .MuiInputBase-input': {
			fontSize: 14,
			padding: '10px',
			fontWeight: 600,
		},
		'& .MuiInput-root': {
			marginTop: 0,
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
	},
	InputPropsStyle: {
		backgroundColor: 'white',
		borderRadius: 25,
	},
	inputPropsStyle: {
		padding: 14,
	},
	createButton: {
		backgroundColor: '#39c9bb',
		color: 'white',
		borderRadius: 25,
		width: '100%',
		padding: '11px 8px',
		'&:hover': {
			backgroundColor: '#39c9bb',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
	auto: {
		'& input': {
			padding: '20px 18px',
		},
		'& div': {
			'& div': {
				// position: 'fixed',
				// maxHeight: '20%',
				// left: 'none',
				padding: 3,
			},
		},
		'& .css-xrz6uu-MuiAutocomplete-root .MuiInput-root .MuiInput-input': {
			padding: '11px 4px 4px 0px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .MuiFormLabel-root': {
			marginLeft: 20,
			fontSize: 13,
		},
		'& .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator':
			{
				marginTop: -10,
			},
	},
	root: {
		'& .InputFromTo': {
			'& .DayPickerInput input': {
				padding: '20px 18px',
			},
		},
	},
	j: {
		'& .MuiBox-root': {
			// alignContent: 'stretch',
			// justifyContent: 'flex-start',
			// flexWrap: 'wrap',
			// display: 'flex',
			display: 'flex',
			alignContent: 'stretch',
			flexWrap: 'wrap',
		},
	},
	down: {
		// opacity: 1,

		// position: 'absolute',
		// top: '100%',
		// left: 0,
		marginTop: '385px',
		minWidth: 300,
		padding: 10,
		background: 'white',
		position: 'absolute',
		boxShadow: '0 0.5rem 1rem rgb(0 0 0 / 15%)',
		// opacity: 0,
		// transform: 'scaleY(0)',
		transformOrigin: 'top',
		transition: 'all .3s ease',
		'@media(min-device-width: 500px) and (max-device-width: 1120px)': {
			marginLeft: 137,
		},
	},
}));
export default function DateRangeNew() {
	const dt = new Date();
	const [value, setValue] = React.useState([
		new Date(),
		dt.setDate(dt.getDate() + 3),
	]);
	const classes = useStyles();
	const dispatch = useDispatch();
	console.log(value);
	const convert = (str) => {
		var date = new Date(str),
			mnth = ('0' + (date.getMonth() + 1)).slice(-2),
			day = ('0' + date.getDate()).slice(-2);
		return [date.getFullYear(), mnth, day].join('-');
	};
	dispatch(setOrigin(convert(value[0])));
	dispatch(setDepart(convert(value[1])));
	dispatch(setOriginO(value[0]));
	dispatch(setDepartO(value[1]));
	console.log(convert(value[0]));
	console.log(convert(value[1]));
	function renderDay(day) {
		const date = day.getDate();
		const dateStyle = {
			position: 'absolute',
			// color: 'lightgray',
			bottom: 0,
			// right: 0.2,
			marginLeft: 16,
			marginTop: 10,
			fontSize: 16,
		};
		const birthdayStyle = { fontSize: '0.8em' };
		const cellStyle = {
			height: 30,
			width: 40,
			position: 'relative',
		};

		return (
			<div style={cellStyle}>
				<div style={dateStyle}>{date}</div>
				<div style={{ fontSize: '8px', marginTop: 26, marginLeft: 10 }}>
					4569
				</div>
				{/* {birthdays[date] &&
					birthdays[date].map((name, i) => (
						<div key={i} style={birthdayStyle}>
							{name}
						</div>
					))} */}
			</div>
		);
	}
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			{/* <Typography sx={{ mt: 2, mb: 1 }}>1 calendar </Typography>
				<DateRangePicker
					calendars={1}
					value={value}
					onChange={(newValue) => {
						setValue(newValue);
					}}
					renderInput={(startProps, endProps) => (
						<React.Fragment>
							<TextField {...startProps} />
							<Box sx={{ mx: 2 }}> to </Box>
							<TextField {...endProps} />
						</React.Fragment>
					)}
				/>
				<Typography sx={{ mt: 2, mb: 1 }}>2 calendars</Typography> */}
			<DateRangePicker
				// calendars={2}
				value={value}
				disablePast
				inputFormat='dd/MM/yyyy'
				// renderDay={renderDay}
				onChange={(newValue) => {
					setValue(newValue);
				}}
				renderInput={(startProps, endProps) => (
					<React.Fragment>
						<TextField
							{...startProps}
							className={classes.selectField}
							endAdornment={
								<InputAdornment position='end'>
									<DateRangeIcon />
								</InputAdornment>
							}
						/>
						<span style={{ marginLeft: 5 }}>-</span>
						<TextField
							{...endProps}
							className={classes.selectField}
							style={{ marginLeft: 10 }}
							endAdornment={
								<InputAdornment position='end'>
									<DateRangeIcon />
								</InputAdornment>
							}
						/>
					</React.Fragment>
				)}
			/>
			{/* <Typography sx={{ mt: 2, mb: 1 }}>3 calendars</Typography>
				<DateRangePicker
					calendars={3}
					value={value}
					onChange={(newValue) => {
						setValue(newValue);
					}}
					renderInput={(startProps, endProps) => (
						<React.Fragment>
							<TextField {...startProps} />
							<Box sx={{ mx: 2 }}> to </Box>
							<TextField {...endProps} />
						</React.Fragment>
					)}
				/> */}
		</LocalizationProvider>
	);
}