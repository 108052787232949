const etravBooking = (
	location,
	response,
	invoice,
	dispatch,
	agentInfo,
	fareQuoteEtrav,
	payment,
	FlightBookEtravDispatch,
	history,
	FlightTicketLCCDispatch,
	FlightBookDispatch,
	agentlogin,
	BookingFormData,
	flightDebitAmount
) => {
	let formEtrav = {
		Customer_Mobile: location.state.formData.phone,
		Passenger_Mobile: location.state.formData.phone,
		orderId: location.state.orderID,
		paymentId: payment === 'offline' ? invoice : invoice,
		WhatsAPP_Mobile: null,
		Passenger_Email: location.state.formData
			? location.state.formData.email
			: '',
		PAX_Details: BookingFormData.map((item, index) => {
			return {
				Pax_Id: index + 1,
				Pax_type: item.PaxType - 1,
				Title: item.title,
				First_Name: item.firstname,
				Last_Name: item.lastname,
				Gender: 1,
				Age: null,
				DOB: item.dob ? item.dob : '1998-03-09',
				Passport_Number: item.passportnumber ? item.passportnumber : null,
				Passport_Issuing_Country: item.passportnumber ? 'INDIA' : null,
				Passport_Expiry: item.expiry ? item.expiry : null,
				Nationality: item.passportnumber ? 'IN' : null,
				FrequentFlyerDetails: null,
			};
		}),

		GST: true,
		GST_Number: '',
		GST_HolderName: 'GST Holder Name',
		GST_Address: 'GST Address',
		BookingFlightDetails: [
			{
				Search_Key: location.state.IDEtrav,
				Flight_Key:
					fareQuoteEtrav.result.AirRepriceResponses[0].Flight.Flight_Key,
				BookingSSRDetails: [],
			},
		],
		CostCenterId: 0,
		ProjectId: 0,
		BookingRemark: `${location.state.body.Segments[0].Origin}-${location.state.body.Segments[0].Destination} ${location.state.body.Segments[0].PreferredDepartureTime} ${invoice}`,
		CorporateStatus: 0,
		CorporatePaymentMode: 0,
		MissedSavingReason: null,
		CorpTripType: null,
		CorpTripSubType: null,
		TripRequestId: null,
		BookingAlertIds: null,
	};
	if (payment === 'offline') {
		let debit = {
			orderId: location.state.orderID,
			paymentId: payment === 'offline' ? invoice : invoice,
		};
		dispatch(flightDebitAmount(debit));
	}

	dispatch(FlightBookEtravDispatch(formEtrav, history, agentlogin));
};

export default etravBooking;
