import React from "react";
import { Grid, Hidden, makeStyles, Box, Divider } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "rgb(8 90 143)",
    marginTop: 10,
    paddingTop: 20,
    paddingBottom: 20,
  },
  link: {
    textDecoration: "none",
    color: "#C4C4C4",
  },
  SolutionDiv: {
    paddingRight: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: 30,
      paddingRight: 0,
      order: 2,
    },
  },
  ExploreDiv: {
    marginTop: 10,
    paddingRight: 20,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: 20,
      order: 1,
      paddingRight: 0,
    },
  },
  ExploreinnerDiv: {
    paddingRight: 20,
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
    },
  },
  logoDiv: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: 20,
      order: 3,
    },
  },
  logoImage: {
    marginBottom: 40,
    marginTop: 50,
    [theme.breakpoints.down("md")]: {
      marginTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
}));
const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { footer_menu, footer_submenu,admin,social } = useSelector((state) => state.home);
  let row = [];
  let rown2 = [];
  let rown3 = [];
  let rown4 = [];
  let rown5 = [];
  let rown6 = [];
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item.parentid === footer_menu?.result[0].id) {
        row.push({
          ...item,
        });
      }
    });
  }
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item.parentid === footer_menu?.result[1].id) {
        rown2.push({
          ...item,
        });
      }
    });
  }
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item.parentid === footer_menu?.result[2].id) {
        rown3.push({
          ...item,
        });
      }
    });
  }
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item.parentid === footer_menu?.result[3].id) {
        rown4.push({
          ...item,
        });
      }
    });
  }
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item.parentid === footer_menu?.result[4].id) {
        rown5.push({
          ...item,
        });
      }
    });
  }
  if (footer_menu && footer_submenu) {
    footer_submenu?.result?.map((item, index) => {
      if (item?.parentid === footer_menu?.result[5]?.id) {
        rown6.push({
          ...item,
        });
      }
    });
  }
  return (
    <>
      <Hidden xlUp>
        <div
          className={classes.mainContainer}
          style={{ paddingLeft: 50, paddingRight: 50 }}
        >
          {/* <Grid container justify="center">
            <Grid container style={{ alignItems: "self-end" }}>
              <Grid item xs={12} lg={6}>
                <p style={{ fontSize: 36, color: "white", textAlign: "right" }}>
                  1800 8890 743
                </p>
              </Grid>
              <Grid item xs={12} lg={6} style={{ textAlign: "center" }}>
                <p
                  style={{
                    marginBottom: "12px",
                    color: "#d6d6d6",
                    fontSize: "14px",
                  }}
                >
                  10:00 AM - 6:00 PM (EST), Monday - Friday
                </p>
              </Grid>
            </Grid>
          </Grid> */}
          <Box
            style={{
              border: "1px solid #727272",
              borderRadius: "12px",
              padding: "7px 3px 3px 2px",
            }}
          >
            <Grid container>
              {/* <Grid item xs={2}></Grid> */}
              <Grid item xs={12} style={{ textAlign: "center", padding: 10 }}>
                <p
                  style={{
                    marginBottom: "12px",
                    color: "#d6d6d6",
                    fontSize: "14px",
                  }}
                >
                 {admin?.result[0]?.address}
                </p>
                <h4 style={{ color: "white" }}>
                  
{admin?.result[0]?.twitter}

                </h4>
              </Grid>
              {/* <Grid item xs={2}></Grid> */}
            </Grid>
          </Box>
          <Grid container style={{ padding: 40 }}>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>
                  {footer_menu?.result[0]?.name}
                </h6>
                {row?.map((i) => {
                  return i.link_url ? (
                    <>
                      <a href={i.link_url} target="_blank">
                        <span style={{ color: "white", fontSize: 12 }}>
                          {i.name}
                        </span>
                      </a>
                      <br />
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          color: "white",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/online/${i.link_url}`,
                            state: i,
                          })
                        }
                      >
                        {i.name}
                      </span>
                      <br />
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>
                  {footer_menu?.result[1]?.name}
                </h6>
                {rown2?.map((i) => {
                  return i.content ? (
                    <>
                      <span
                        style={{
                          color: "white",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/online/${i.link_url}`,
                            state: i,
                          })
                        }
                      >
                        {i.name}
                      </span>

                      <br />
                    </>
                  ) : (
                    <>
                      <a href={i.link_url} target="_blank">
                        <span style={{ color: "white", fontSize: 12 }}>
                          {i.name}
                        </span>
                      </a>
                      <br />
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>
                  {footer_menu?.result[2]?.name}
                </h6>
                {rown3?.map((i) => {
                  return i.content ? (
                    <>
                      <span
                        style={{
                          color: "white",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/online/${i.link_url}`,
                            state: i,
                          })
                        }
                      >
                        {i.name}
                      </span>

                      <br />
                    </>
                  ) : (
                    <>
                      <a href={i.link_url} target="_blank">
                        <span style={{ color: "white", fontSize: 12 }}>
                          {i.name}
                        </span>
                      </a>
                      <br />
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>
                  {footer_menu?.result[3]?.name}
                </h6>
                {rown4?.map((i) => {
                  return i.content ? (
                    <>
                      <span
                        style={{
                          color: "white",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/online/${i.link_url}`,
                            state: i,
                          })
                        }
                      >
                        {i.name}
                      </span>

                      <br />
                    </>
                  ) : (
                    <>
                      {i.link_url === "https://b2b.marinersforex.com/" ? (
                        <span style={{ color: "white", fontSize: 12 }}>
                          {i.name}
                        </span>
                      ) : (
                        <>
                          <a href={i.link_url} target="_blank">
                            <span style={{ color: "white", fontSize: 12 }}>
                              {i.name}
                            </span>
                          </a>
                          <br />
                        </>
                      )}
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>
                  {footer_menu?.result[4]?.name}
                </h6>
                {rown5?.map((i) => {
                  return i.content ? (
                    <>
                      <span
                        style={{
                          color: "white",
                          fontSize: 12,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          history.push({
                            pathname: `/online/${i.link_url}`,
                            state: i,
                          })
                        }
                      >
                        {i.name}
                      </span>

                      <br />
                    </>
                  ) : (
                    <>
                      <a href={i.link_url} target="_blank">
                        <span style={{ color: "white", fontSize: 12 }}>
                          {i.name}
                        </span>
                      </a>
                      <br />
                    </>
                  );
                })}
              </Box>
            </Grid>
            <Grid item xs={12} lg={2}>
              <Box>
                <h6 style={{ color: "white" }}>PAYMENT & SECURITY</h6>
                <img
                  src="https://marinersforex.com/images/pgicon.png"
                  style={{ maxWidth: "100%" }}
                />
                <h6 style={{ color: "white", marginTop: 10, fontSize: 12 }}>
                  FOLLOW US ON
                </h6>
                {social&&social.result.length>0&&social?.result.map((i,index)=>{
									return <a href={i.url} target='_new' style={{ marginLeft: index===0?0:10 }}>
									<i
										class={i.logo}
										aria-hidden='true'
										style={{ color: 'white' }}></i>
								</a>
								})}
                <br></br>
                {/* <h6 style={{ color: "white", fontSize: 12 }}>
                  Download<br></br>
                  <span style={{ color: "white", fontSize: 12 }}>
                  {admin?.result[0]?.admin_name} App
                  </span>
                </h6>
                <a
                  // href="https://play.google.com/store/apps/details?id=com.mariners.marinersforex"
                  target="_new"
                >
                  <img
                    src="https://reefcentral.pt/wp-content/uploads/2019/04/get-on-google-play.png"
                    width="70%"
                  />
                </a> */}
              </Box>
            </Grid>
          </Grid>
          {/* <Box style={{ textAlign: "end" }}>
            <a href="" target="_new">
              <img
                src="https://marinersforex.com/images/services-banner.png"
                width="50%"
                style={{ height: 30 }}
              />
            </a>
          </Box> */}
          <Divider style={{ background: "white", marginTop: 10 }} />
          <h5 style={{ textAlign: "center", color: "white", fontSize: 12 }}>
            Copyright ©2022, All Rights Reserved. Powered by R Arya Aviation India Pvt.
            Ltd.
          </h5>
        </div>
      </Hidden>
      
    </>
  );
};

export default Footer;
