import React, { useState } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import bus from '../assets/images/bus.png';
import { useDispatch, useSelector } from 'react-redux';
import DateRangeNew from '../components/reusableComponents/DateRangeNew';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
	SearchReturnFormDispatch,
	SearchFormDispatch,
} from '../reducers/HomeReducer';
import {
	fetchBusCityDispatch,
	fetchBusCityDesDispatch,
	addBusSearchDispatch,
} from '../reducers/BusReducer';
import { setSelectedBus, setSelectedDesBus } from '../reducers/UiReducer';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import BusListDate from '../components/reusableComponents/BusListDate';
const useStyles = makeStyles((theme) => ({
	root: {
		// "& .MuiFormControl-root": {
		//   "& div": {
		//     "& div": {
		//       display: "none",
		//     },
		//   },
		// },
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		// "& .MuiInput-underline:after": {
		//   borderBottom: "none",
		// },
		'& input': {
			fontSize: 14,
			fontWeight: 600,
			color: 'white',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		// "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot":
		//   {
		//     paddingRight: 0,
		//   },
		// '& div': {
		// 	'& div': {
		// 		'& div': {
		// 			paddingRight: 0,
		// 		},
		// 	},
		// },
		'& .MuiAutocomplete-endAdornment': {
			display: 'none',
		},
		'& .MuiAutocomplete-root': {
			padding: 10,
		},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'white',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'white',
			fontSize: '18px',
			fontWeight: 800,
		},
	},
	radio2: {
		'& .MuiRadio-root': {
			color: 'black',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'black',
			fontSize: '14px',
			// fontWeight: 800,
		},
	},
	popper: {
		'& .MuiAutocomplete-listbox': {
			'& :hover': {
				color: 'rgb(239, 51, 57)',
				// '& img': {
				// 	background: 'rgb(239, 51, 57)',
				// },
			},
		},
	},
}));
const BusListForm = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { buscity, busDes } = useSelector((state) => state.bus);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const [data, setData] = useState(true);
	const theme = useTheme();
	const [filterData, setFilterData] = useState('');
	const [filterData2, setFilterData2] = useState('');
	const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const { origin, depart, selectedBus, selectedDesBus } = useSelector(
		(state) => state.ui
	);

	let invoiceOptions = [];
	let demo = [];
	city &&
		city.data &&
		city.data.length > 0 &&
		city.data.forEach((invoice, index) => {
			invoiceOptions.push({
				code: invoice.iata_code,
				city: invoice.city,
				country: invoice.country_name,
			});
			if (index < 50) {
				demo.push({
					code: invoice.iata_code,
					city: invoice.city,
					country: invoice.country_name,
				});
			}
		});
	const handleSubmit = (data) => {
		let formData = {
			departure_city: selectedBus.name.split(' ')[0],
			arrival_city: selectedDesBus.name.split(' ')[0],
			sourceid: selectedBus.id,
			destinationid: selectedDesBus.id,
			dateofjourney: origin,
		};
		history.push({ pathname: '/buslist', state: { formdata: formData } });
		console.log(formData);
		dispatch(addBusSearchDispatch(formData));
		// dispatch(addBusSearchDispatch(location.state.formdata));
	};

	const handleSearchOrigin = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		// console.log(value);
		results =
			buscity &&
			buscity?.result &&
			buscity?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);
		if (event) {
		} else {
			dispatch(setSelectedBus(results ? results[0] : null));
			if (results) {
				let formData = {
					source: results[0].id,
				};
				dispatch(fetchBusCityDesDispatch(formData));
			}
		}
		if (results?.length <= 80) {
			setFilterData(results);
		}
	};
	const handleSearchOrigin2 = (event) => {
		let value = event ? event.target.value.toLowerCase() : 'del';
		let results = [];
		// console.log(value);
		results =
			busDes &&
			busDes?.result &&
			busDes?.result?.cities?.filter((item) => {
				return (
					item.name.toLowerCase().includes(value.toLowerCase()) ||
					item.state.toLowerCase().includes(value.toLowerCase())
				);
			});
		console.log('searchresult', results);

		if (results?.length < 80) {
			setFilterData2(results);
		}
	};
	console.log(leaving);
	console.log(going);
	const handleChange2 = (e) => {
		console.log(e.target.value);
		dispatch(setTrip(e.target.value));
	};

	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form style={{ marginTop: matches ? 50 : '' }}>
							<Grid
								container
								spacing={2}
								alignItems='center'
								style={{ marginTop: 10 }}>
								<Grid item xs={12} md={12} lg={6}>
									<Grid container alignItems='center' spacing={2}>
										<Grid
											item
											xs={6}
											style={{ display: 'flex', alignItems: 'center' }}>
											<div className={classes.root} style={{ width: '100%' }}>
												{/* <span style={{ color: 'white' }}>From</span> */}
												<Autocomplete
													id='country-select-demo'
													sx={{
														background: 'rgb(25, 81, 181)',
														// borderRadius: 2,
														padding: '10px',
													}}
													value={selectedBus}
													options={
														filterData && filterData.length > 0
															? filterData
															: []
													}
													autoHighlight
													getOptionLabel={(option) =>
														`${option.name} (${option.state})`
													}
													renderOption={(props, option) => (
														<Box
															mr={'5px'}
															component='li'
															sx={{ '& > img': { mr: 2 } }}
															{...props}>
															<img loading='lazy' width='20' src={bus} />
															<p style={{ marginLeft: 5, fontSize: 16 }}>
																{option.name} ({option.state})
															</p>
														</Box>
													)}
													onChange={(event, value) => {
														// setFieldValue('Origin', value.code);
														// console.log(value.code);
														// setLeaving(value.code);
														dispatch(setSelectedBus(value));
														console.log('Des', value);
														let formData = {
															source: value.id,
														};
														dispatch(fetchBusCityDesDispatch(formData));
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={handleSearchOrigin}
															name='origin'
														/>
													)}
												/>
											</div>
											{/* <SwapHorizontalCircleIcon
												onClick={() => {
													setLeaving(going);
													setGoing(leaving);
													// setData(false);

													// console.log('first');
												}}
												style={{
													color: 'white',
													width: '4em',
													height: '3em',
													marginTop: 20,
													cursor: 'pointer',
												}}
											/> */}
										</Grid>
										{/* <Grid item xs={2} style={{ marginTop: 17 }}>
											
										</Grid> */}
										<Grid item xs={6}>
											<div className={classes.root}>
												{/* <span style={{ color: 'white' }}>To</span> */}
												<Autocomplete
													id='country-select-demo'
													sx={{
														background: 'rgb(25, 81, 181)',
														// borderRadius: 2,
														padding: '10px',
													}}
													value={
														selectedDesBus
															? selectedDesBus
															: busDes?.result?.cities[0]
													}
													options={
														filterData2 && filterData2.length > 0
															? filterData2
															: []
													}
													autoHighlight
													getOptionLabel={(option) =>
														`${option.name} (${option.state})`
													}
													renderOption={(props, option) => (
														<Box
															mr={'5px'}
															component='li'
															sx={{ '& > img': { mr: 2 } }}
															{...props}>
															<img loading='lazy' width='20' src={bus} />
															<p style={{ marginLeft: 5, fontSize: 16 }}>
																{option.name} ({option.state})
															</p>
														</Box>
													)}
													onChange={(event, value) => {
														// setFieldValue('Destination', value.code);
														// setGoing(value.code);
														console.log('Des', value);
														dispatch(setSelectedDesBus(value));
														// dispatch(fetchBusCityDesDispatch())
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															onChange={handleSearchOrigin2}
														/>
													)}
												/>
											</div>
										</Grid>
									</Grid>
								</Grid>
								{/* <Grid item xs={12} md={6} lg={2}>
									<div className={classes.root}>
										<span style={{ color: 'white' }}>Going To</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												background: '#eee',
												borderRadius: 2,
												padding: '10px',
											}}
											options={result2 && result2.length > 0 ? result2 : []}
											autoHighlight
											getOptionLabel={(option) => option.code}
											renderOption={(props, option) => (
												<Box
													component='li'
													sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
													{...props}>
													<img loading='lazy' width='20' src={flt} />
													{option.city} ({option.code}) {option.country}
												</Box>
											)}
											onChange={(event, value) =>
												setFieldValue('Destination', value.code)
											}
											renderInput={(params) => (
												<TextField {...params} onChange={handleSearch2} />
											)}
										/>
									</div>
								</Grid> */}
								<Grid
									item
									xs={12}
									md={12}
									lg={trip == 'oneway' ? 3 : trip == 'roundtrip' ? 3 : 3}>
									{/* <div className={classes.root}> */}
									<Box display='flex' justifyContent='space-between'>
										{/* <span style={{ color: 'white' }}>Depart Date</span> */}
										{trip == 'oneway' ? (
											''
										) : trip == 'roundtrip' ? (
											<span style={{ color: 'white' }}>Return Date</span>
										) : (
											''
										)}
										{/* <span style={{ color: 'white' }}>Return Date</span> */}
									</Box>
									{trip == 'oneway' ? (
										<BusListDate />
									) : trip == 'roundtrip' ? (
										<DateRangeNew />
									) : (
										<BusListDate />
									)}
									{/* <DateRangeNew /> */}
									{/* <DesktopDateNew /> */}
									{/* </div> */}
								</Grid>

								<Grid item xs={12} md={6} lg={3}>
									<Box
										onClick={handleSubmit}
										value='Search'
										// className='btn'
										style={{
											width: '100%',
											fontSize: 16,
											// borderRadius: 5,
											backgroundColor: 'white',
											color: 'black',
											fontWeight: 'bold',
											textAlign: 'center',
											padding: 7,
											cursor: 'pointer',
										}}>
										Search
									</Box>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default BusListForm;
