const addINtBooking = (
    location,
    agentlogin,
    payment,
    payableAmout,
    priceDetails,
    selectedFlight,
    dispatch,
    FlightAddBookingDispatch,
    PGCharge,
    BookingFormData
  ) => {
    let formaddBooking = {
      orderId: location.state.orderID,
      supplier: location.state.intFlight.Supplier,
      userEmail: location.state.formData.email,
      userPhone: location.state.formData.phone,
      user_type: agentlogin && agentlogin.status === 200 ? "agent" : "guest",
      origin: location.state.returnBody.Segments[0].Origin,
      destination: location.state.returnBody.Segments[0].Destination,
      travelDate: location.state.returnBody.Segments[0].PreferredDepartureTime,
      returnDate: "",
      journeyType: location.state.returnBody.JourneyType === 1 ? "OneWay" : "",
      origin1: "",
      destination1: "",
      travelDate1: "",
      origin2: "",
      destination2: "",
      travelDate2: "",
      origin3: "",
      destination3: "",
      travelDate3: "",
      PGCharge: payment === "pay" ? 200 : 0,
      class: "Economy",
      nop: `${location.state.returnBody.AdultCount} | ${location.state.returnBody.ChildCount} | ${location.state.returnBody.InfantCount} `,
      airlineType: "",
      carrier: location.state.intFlight.AirlineCode
        ? location.state.intFlight.AirlineCode
        : location.state.intFlight.Airline_Code,
      fareType: "R",
      eTicketType: "eTicket",
      actualFare:
        location.state.intFlight.Supplier === "TBO"
          ? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fare.markup)) -
            Number(Math.round(location.state.intFlight.Fare.partnerMarkup)) -
            Number(Math.round(location.state.intFlight.Fare.agentMarkup))
          : Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fares.markup)) -
            Number(Math.round(location.state.intFlight.Fares.partnerMarkup)) -
            Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
      markup:
        location.state.intFlight.Supplier === "TBO"
          ? Math.round(location.state.intFlight.Fare.markup)
          : Math.round(location.state.intFlight.Fares.markup),
      baseFare:
        location.state.intFlight.Supplier === "TBO"
          ? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fare.partnerMarkup)) -
            Number(Math.round(location.state.intFlight.Fare.agentMarkup))
          : Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fares.partnerMarkup)) -
            Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
      partnerMarkup:
        location.state.intFlight.Supplier === "TBO"
          ? Math.round(location.state.intFlight.Fare.partnerMarkup)
          : Math.round(location.state.intFlight.Fares.partnerMarkup),
      agentMarkup:
        location.state.intFlight.Supplier === "TBO"
          ? Math.round(location.state.intFlight.Fare.agentMarkup)
          : Math.round(location.state.intFlight.Fares.agentMarkup),
      totalFare:
        location.state.intFlight.Supplier === "TBO"
          ? Number(Math.round(location.state.intFlight.Fare.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fare.agentMarkup))
          : Number(Math.round(location.state.intFlight.Fares.Total_Amount)) -
            Number(Math.round(location.state.intFlight.Fares.agentMarkup)),
      payableAmount: payableAmout,
      ticketCost: Number(payableAmout) + Number(payment === "pay" ? 200 : 0),
      totalEarn: 0,
      cashBack: "0.00",
      couponDiscount: 0.0,
      couponDetails: "",
      pricingDetails: priceDetails,
      selectedFlight: selectedFlight,
      airlineType: location.state.intFlight.Airline_Code
        ? location.state.intFlight.Block_Ticket_Allowed
          ? "LCC"
          : "GDS"
        : location.state.intFlight.IsLCC
        ? "LCC"
        : "GDS",
      selectedPax: BookingFormData,
      ticketSent: 0,
      paymentMode: agentlogin
        ? payment === "offline"
          ? "OFFLINE"
          : "ONLINE"
        : "ONLINE",
      agent_id:
        agentlogin && agentlogin.status === 200
          ? agentlogin && agentlogin.result.result.id
          : 0,
    };
    dispatch(FlightAddBookingDispatch(formaddBooking,payment));
    // console.log("add booking", formaddBooking);
  };
  export default addINtBooking;
  