import React, { useState } from 'react';
import {
	makeStyles,
	Button,
	MenuItem,
	LinearProgress,
	Box,
	// TextField,
	InputAdornment,
	Grid,
	Tabs,
	Tab,
	Divider,
	withStyles,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';

import { TextField } from 'formik-material-ui';
// import TextField from '@material-ui/core/TextField
import { Field, Form, Formik } from 'formik';
import PhoneIcon from '@material-ui/icons/Phone';
import TitleText from '../../components/reusableComponents/TitleText';
import { useMediaQuery, useTheme } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import BodyText from './BodyText';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useDispatch, useSelector } from 'react-redux';
import { agentEditMarkupDispatch } from '../../reducers/UserReducer';
import SuccessModal from './SuccessModal';
import ErrorModal from './ErrorModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useStyles = makeStyles((theme) => ({
	// selectField: {
	// 	'& .MuiOutlinedInput-root': {
	// 		'& fieldset': {
	// 			border: '1px solid #959595',
	// 		},
	// 	},
	// 	backgroundColor: 'white',
	// 	marginBottom: 30,
	// 	width: '100%',
	// 	marginTop: 10,
	// 	borderRadius: 10,
	// 	[theme.breakpoints.down('xs')]: {
	// 		marginBottom: 15,
	// 		marginTop: 8,
	// 	},
	// 	'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
	// 		fontSize: 12,
	// 	},
	// },
	selectField: {
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				border: '1px solid rgba(0, 50, 91, 0.5)',
			},
		},
		'& .MuiFormHelperText-root': {
			backgroundColor: '#F6F8FB',
			margin: 0,
			paddingTop: 10,
		},
		'& .MuiSelect-icon': {
			color: '#E61855',
		},
		'& div': {
			fontSize: 18,
		},
		'& input': {
			padding: '12.5px 14px',
		},
		'& .MuiSelect-select': {
			padding: '12.5px 14px',
		},

		backgroundColor: 'white',
		marginBottom: 20,
		width: '100%',
		marginTop: 10,
		borderRadius: 15,
		[theme.breakpoints.down('xs')]: {
			marginBottom: 0,
			marginTop: 8,
		},
	},
	InputPropsStyle: {
		backgroundColor: '#F6F8FB',
		borderRadius: 8,
		fontSize: 18,
	},
	inputPropsStyle: {
		// padding: 14,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#F6F8FB',
	},
	createButton: {
		backgroundColor: '#E61855',
		color: 'white',
		borderColor: '#E61855',
		borderRadius: 15,
		width: '100%',
		padding: 14,
		fontSize: 16,
		marginBottom: 20,
		'&:hover': {
			backgroundColor: '#E61855',
		},
	},
	tabBorder: {
		borderBottom: '2px solid transparent',
		color: '#7B95AB',
		fontWeight: 400,
	},
	tabBorderColor: {
		borderBottom: '2px solid #313131',
		color: '#00325B',
		fontWeight: 700,
	},
	tab: {
		[theme.breakpoints.up('sm')]: {
			'& .MuiTab-root': {
				minWidth: 241,
				fontSize: 22,
			},
		},
	},
}));
// const country = ['INDIA', 'SRILANKA'];
const GreenCheckbox = withStyles({
	root: {
		color: '#39c9bb',
		'&$checked': {
			color: '#39c9bb',
		},
	},
	checked: {},
})((props) => <Checkbox color='default' {...props} />);
const markup = ['Rs', '%'];
const ManageMarkupForm = () => {
	const classes = useStyles();
	// const theme = useTheme();
	const [tabvalue, setValue] = React.useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [check, setCheck] = useState(false);
	const { agentmarkup, agentlogin } = useSelector((state) => state.user);
	const handleCheck = (event) => {
		setCheck(event.target.checked);
	};
	const [currentStep, SetCurrentStep] = useState('Step 1');
	const dispatch = useDispatch();
	const history = useHistory();

	// const matches = useMediaQuery(theme.breakpoints.down('xs'));
	const handleSubmit = (data) => {
		const markup = [
			{
				inventory:
					agentmarkup && agentmarkup.result && agentmarkup.result[6].inventory,
				agent_id: agentlogin && agentlogin.result.result.id,
				type: data.Domestictype,
				value: data.Domestic,
			},
			{
				inventory:
					agentmarkup && agentmarkup.result && agentmarkup.result[4].inventory,
				agent_id: agentlogin && agentlogin.result.result.id,
				type: data.HotelType,
				value: data.Hotel,
			},
			{
				inventory:
					agentmarkup && agentmarkup.result && agentmarkup.result[3].inventory,
				agent_id: agentlogin && agentlogin.result.result.id,
				type: data.InHotelType,
				value: data.InHotel,
			},
			{
				inventory:
					agentmarkup && agentmarkup.result && agentmarkup.result[2].inventory,
				agent_id: agentlogin && agentlogin.result.result.id,
				type: data.BusType,
				value: data.Bus,
			},
			{
				inventory:
					agentmarkup && agentmarkup.result && agentmarkup.result[5].inventory,
				agent_id: agentlogin && agentlogin.result.result.id,
				type: data.InFlightType,
				value: data.InFlight,
			},
		];
		let formdata = {
			markup: markup,
		};
		// console.log(data);
		// let Domestic = {
		// 	inventory:
		// 		agentmarkup && agentmarkup.result && agentmarkup.result[6].inventory,
		// 	agent_id: agentlogin && agentlogin.result.result.id,
		// 	type: data.Domestictype,
		// 	value: data.Domestic,
		// };
		// let Hotel = {
		// 	inventory:
		// 		agentmarkup && agentmarkup.result && agentmarkup.result[4].inventory,
		// 	agent_id: agentlogin && agentlogin.result.result.id,
		// 	type: data.HotelType,
		// 	value: data.Hotel,
		// };
		// let InHotel = {
		// 	inventory:
		// 		agentmarkup && agentmarkup.result && agentmarkup.result[3].inventory,
		// 	agent_id: agentlogin && agentlogin.result.result.id,
		// 	type: data.InHotelType,
		// 	value: data.InHotel,
		// };
		// let Bus = {
		// 	inventory:
		// 		agentmarkup && agentmarkup.result && agentmarkup.result[2].inventory,
		// 	agent_id: agentlogin && agentlogin.result.result.id,
		// 	type: data.BusType,
		// 	value: data.Bus,
		// };
		// let InFlight = {
		// 	inventory:
		// 		agentmarkup && agentmarkup.result && agentmarkup.result[5].inventory,
		// 	agent_id: agentlogin && agentlogin.result.result.id,
		// 	type: data.InFlightType,
		// 	value: data.InFlight,
		// };
		dispatch(
			agentEditMarkupDispatch(
				formdata,
				history,
				agentlogin && agentlogin.result.result.id
			)
		);
		// dispatch(
		// 	agentEditMarkupDispatch(
		// 		Hotel,
		// 		history,
		// 		agentlogin && agentlogin.result.result.id
		// 	)
		// );
		// dispatch(
		// 	agentEditMarkupDispatch(
		// 		InHotel,
		// 		history,
		// 		agentlogin && agentlogin.result.result.id
		// 	)
		// );
		// dispatch(
		// 	agentEditMarkupDispatch(
		// 		Bus,
		// 		history,
		// 		agentlogin && agentlogin.result.result.id
		// 	)
		// );
		// dispatch(
		// 	agentEditMarkupDispatch(
		// 		InFlight,
		// 		history,
		// 		agentlogin && agentlogin.result.result.id
		// 	)
		// );
	};

	return (
		<>
			<Formik
				initialValues={{
					Domestic:
						agentmarkup && agentmarkup.result && agentmarkup.result[6].value,
					Domestictype:
						agentmarkup && agentmarkup.result && agentmarkup.result[6].type,
					Hotel:
						agentmarkup && agentmarkup.result && agentmarkup.result[4].value,
					HotelType:
						agentmarkup && agentmarkup.result && agentmarkup.result[4].type,
					InHotel:
						agentmarkup && agentmarkup.result && agentmarkup.result[3].value,
					InHotelType:
						agentmarkup && agentmarkup.result && agentmarkup.result[3].type,
					Bus: agentmarkup && agentmarkup.result && agentmarkup.result[2].value,
					BusType:
						agentmarkup && agentmarkup.result && agentmarkup.result[2].type,
					InFlight:
						agentmarkup && agentmarkup.result && agentmarkup.result[5].value,
					InFlightType:
						agentmarkup && agentmarkup.result && agentmarkup.result[5].type,
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleSubmit}>
				{({ submitForm, isSubmitting, values }) => (
					<>
						<Form>
							<>
								<Box>
									{/* <h2 style={{ fontFamily: 'Josefin Sans, sans-serif' }}>
										Manage Markup
									</h2> */}
								</Box>
								<Grid container spacing={4} style={{ marginTop: 20,padding:50 }}>
									<Grid item xs={12} lg={6}>
										<Grid container spacing={2}>
											<Grid item xs={8}>
												<h5>Domestic Flight Markup</h5>
												<Field
													component={TextField}
													name='Domestic'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<h5>Type</h5>
												<Field
													component={TextField}
													select
													name='Domestictype'
													type='text'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}>
													{markup.map((item, index) => (
														<MenuItem key={index} value={item}>
															{item}
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={8} style={{ marginTop: 5 }}>
												<h5>Hotel Markup</h5>
												<Field
													component={TextField}
													name='Hotel'
													type='text'
													variant='outlined'
													// label='Hotel Markup'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}
												/>
											</Grid>
											<Grid item xs={4} style={{ marginTop: 5 }}>
												<h5>Type</h5>
												<Field
													component={TextField}
													select
													name='HotelType'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}>
													{markup.map((item, index) => (
														<MenuItem key={index} value={item}>
															{item}
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={8} style={{ marginTop: 5 }}>
												<h5>International Hotel Markup</h5>
												<Field
													component={TextField}
													name='InHotel'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}
												/>
											</Grid>
											<Grid item xs={4} style={{ marginTop: 5 }}>
												<h5>Type</h5>
												<Field
													component={TextField}
													select
													name='InHotelType'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}>
													{markup.map((item, index) => (
														<MenuItem key={index} value={item}>
															{item}
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
										<Grid container spacing={2}>
											<Grid item xs={8} style={{ marginTop: 5 }}>
												<h5>Bus Markup</h5>
												<Field
													component={TextField}
													name='Bus'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}
												/>
											</Grid>
											<Grid item xs={4} style={{ marginTop: 5 }}>
												<h5>Type</h5>
												<Field
													component={TextField}
													select
													name='BusType'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}>
													{markup.map((item, index) => (
														<MenuItem key={index} value={item}>
															{item}
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12} lg={6}>
										<Grid container spacing={2}>
											<Grid item xs={8}>
												<h5>International Flight Markup</h5>
												<Field
													component={TextField}
													name='InFlight'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}
												/>
											</Grid>
											<Grid item xs={4}>
												<h5>Type</h5>
												<Field
													component={TextField}
													select
													name='InFlightType'
													type='text'
													variant='outlined'
													className={classes.selectField}
													InputProps={{
														className: classes.InputPropsStyle,
													}}
													inputProps={{
														className: classes.inputPropsStyle,
													}}>
													{markup.map((item, index) => (
														<MenuItem key={index} value={item}>
															{item}
														</MenuItem>
													))}
												</Field>
											</Grid>
										</Grid>
										<Grid container>
											<Grid item xs={8} style={{ marginTop: 40 }}>
												<Button
													className={classes.createButton}
													onClick={submitForm}
													style={{ marginTop: 5 }}
													// disabled={isSubmitting}
												>
													SAVE CHANGE
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</>
						</Form>
					</>
				)}
			</Formik>
			<SuccessModal />
			<ErrorModal />
		</>
	);
};

export default ManageMarkupForm;
