import React, { useState } from 'react';
import {
	Grid,
	TextField,
	Box,
	makeStyles,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	useMediaQuery,
	useTheme,
	// FormControl,
} from '@material-ui/core';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import bus from '../assets/images/bus.png';
import { useDispatch, useSelector } from 'react-redux';
import DateRange from '../components/reusableComponents/DateRange';
import DateRangeNew from '../components/reusableComponents/DateRangeNew';
import BodyText from '../components/reusableComponents/BodyText';
import TitleText from '../components/reusableComponents/TitleText';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { SearchReturnFormDispatch } from '../reducers/HomeReducer';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import DesktopDate from '../components/reusableComponents/DesktopDate';
import DesktopDateNew from '../components/reusableComponents/DesktopDateNew';
import DesktopDateBus from '../components/reusableComponents/DesktopDateBus';
import { fetchBusCityDispatch } from '../reducers/BusReducer';
import {
	fetchHotelCityDispatch,
	hotelSearchDispatch,
} from '../reducers/HotelReducer';
import {
	removeSelectedRoom,
	setAdultH,
	setHotelToggle,
	setSelectedHotel,
	setSelectedRoom,
	setChildH,
	setChildAge1,
	setChildAge2,
	setChildAge3,
	setChildAge4,
} from '../reducers/UiReducer';
import { Recommend } from '@mui/icons-material';
const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiFormControl-root': {
			'& div': {
				'& div': {
					display: 'none',
				},
			},
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& input': {
			fontSize: '.8em',
			fontWeight: 600,
			padding: '0px 4px 4px 0px',
			[theme.breakpoints.down('xs')]: {
				fontSize: 12,
			},
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},

		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-184whfw-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
		'& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-xzqck1-MuiAutocomplete-root .MuiAutocomplete-inputRoot':
			{
				paddingRight: 0,
			},
	},
	radio: {
		'& .MuiRadio-root': {
			color: 'white',
		},
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: '#f50057',
		},
		'& .MuiTypography-body1': {
			color: 'white',
			fontSize: 14,
		},
	},
}));
const HotelForm = () => {
	const [result, setResult] = useState([]);
	const [result2, setResult2] = useState([]);
	const classes = useStyles();
	const { city } = useSelector((state) => state.home);
	const { buscity } = useSelector((state) => state.bus);
	// const [toggle, setToggle] = useState(false);
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const { hotelcity } = useSelector((state) => state.hotel);
	const handleRadio = (event) => {
		setValue(event.target.value);
	};
	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};
	const history = useHistory();
	const dispatch = useDispatch();
	const [data, setData] = useState([1]);

	const [adult, setAdult] = useState(1);
	const [child, setChild] = useState(0);
	const [infant, setInfant] = useState(0);
	const [value, setValue] = useState('Economy');
	const [leaving, setLeaving] = useState('DEL');
	const [trip, setTrip] = useState('oneway');
	const [going, setGoing] = useState('CCU');
	const {
		origin,
		selectedHotelDes,
		depart,
		hoteltoggle,
		room,
		adultH,
		childH,
		childAge1,
		childAge2,
		childAge3,
		childAge4,
	} = useSelector((state) => state.ui);
	let invoiceOptions = [];
	let number = [1];
	// let adultH = [];
	// let childH = [];
	console.log('adultH', adultH);
	console.log('childH', childH);
	console.log('childAge1', childAge1);
	console.log('childAge2', childAge2);
	console.log('childAge3', childAge3);
	console.log('childAge4', childAge4);
	// city &&
	// 	city.data &&
	// 	city.data.length > 0 &&
	// 	city.data.forEach((invoice, index) => {
	// 		invoiceOptions.push({
	// 			code: invoice.iata_code,
	// 			city: invoice.city,
	// 			country: invoice.country_name,
	// 		});
	// 		if (index < 50) {
	// 			demo.push({
	// 				code: invoice.iata_code,
	// 				city: invoice.city,
	// 				country: invoice.country_name,
	// 			});
	// 		}
	// 	});
	const handleHotel = (data) => {
		console.log('first', data);

		let formData = {
			checkInDate: origin,
			checkOutDate: depart,
			noOfNights: 1,
			countryName: selectedHotelDes.country_name,
			countryCode: selectedHotelDes.country_code,
			cityName: selectedHotelDes.city_name,
			cityId: selectedHotelDes.city_id,
			noOfRooms: room.length,
			guestNationality: 'IN',
			adultPax: adultH,
			childPax: childH,
			childAge: [...childAge1, ...childAge2, ...childAge3, ...childAge4],
			preferredCurrencyCode: 'INR',
			hotelCodeList: '0',
			starMaxRating: 3,
			startMinRating: 2,
			// sessionId: '4bf6d1d4-afb3-416c-82c5-551e702e3e7e',
		};
		history.push({
			pathname: '/hotellist',
			state: { hotelformData: formData },
		});
		// dispatch(hotelSearchDispatch(formData, history));
	};
	console.log(invoiceOptions);
	// const handleSearch = (event) => {
	// 	let value = event.target.value.toLowerCase();
	// 	console.log(value);
	// 	if (value.length >= 2) {
	// 		let results =
	// 			invoiceOptions &&
	// 			invoiceOptions.filter((data) => {
	// 				return Object.values(data).join('').toLowerCase().includes(value);
	// 			});
	// 		setResult(results);
	// 	} else {
	// 		setResult([]);
	// 	}
	// };
	// const handleSearch2 = (event) => {
	// 	let value = event.target.value.toLowerCase();
	// 	let results = [];
	// 	console.log(value);
	// 	if (value.length >= 2) {
	// 		results =
	// 			invoiceOptions &&
	// 			invoiceOptions.filter((data) => {
	// 				return Object.values(data).join('').toLowerCase().includes(value);
	// 			});
	// 		setResult2(results);
	// 	} else {
	// 		setResult2([]);
	// 	}
	// };
	console.log(leaving);
	console.log(going);
	const handleChange2 = (e) => {
		console.log(e.target.value);
		dispatch(setTrip(e.target.value));
	};
	const handleSearchOrigin = (event) => {
		let value = event.target.value.toLowerCase();
		// console.log(value);
		let cityData = {
			keywords: value,
			limits: 10,
		};
		// dispatch(fetchBusCityDispatch(cityData));
		dispatch(fetchHotelCityDispatch(cityData));
	};
	return (
		<>
			<Formik
				initialValues={{
					Origin: '',
					Destination: '',
				}}
				validate={(values) => {
					const errors = {};
				}}
				onSubmit={handleHotel}>
				{({ submitForm, isSubmitting, setFieldValue, values }) => (
					<>
						<Form>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} md={12} lg={3}>
									<div
										className={classes.root}
										style={{ width: '100%', marginTop: matches ? 25 : '' }}>
										<span
											style={{
												color: 'black',
												fontSize: matches ? 12 : '.8em',
											}}>
											Where are you going ?
										</span>
										<Autocomplete
											id='country-select-demo'
											sx={{
												border: '1px solid lightgrey',
												borderRadius: '4px',
												padding: '7px',
												transition: 'none',
											}}
											// placeholder='Where are you going ?'
											options={
												hotelcity && hotelcity.result.length > 0
													? hotelcity.result
													: []
											}
											autoHighlight
											getOptionLabel={(option) =>
												`${option.city_name} (${option.country_name})`
											}
											renderOption={(props, option) => (
												<Box
													mr={'5px'}
													style={{
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
													component='li'
													sx={{ '& > img': { mr: 2 } }}
													{...props}>
													<Box>
														<Box display='flex' alignItems='center'>
															<img
																loading='lazy'
																width='20'
																src={
																	'https://cdn-icons-png.flaticon.com/512/235/235889.png'
																}
															/>
															<p style={{ marginLeft: 5, fontSize: 14 }}>
																{option.city_name} ({option.country_name})
															</p>
														</Box>
													</Box>
													<img
														style={{ marginLeft: 10 }}
														loading='lazy'
														width='30'
														src={`https://countryflagsapi.com/svg/${option.country_code}`}
														alt=''
													/>
												</Box>
											)}
											onChange={(event, value) => {
												setFieldValue('Origin', value.code);
												console.log(value.code);
												dispatch(setSelectedHotel(value));
												// setLeaving(value.code);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													onChange={handleSearchOrigin}
													name='origin'
												/>
											)}
										/>
									</div>
									{/* </Box> */}
								</Grid>

								<Grid item xs={12} md={12} lg={3} style={{ marginTop: 20 }}>
									{/* <div className={classes.root}> */}

									{/* <Box
                          style={{
                            border: "1px solid #767b8e",
                            borderRadius: 5,
                            paddingLeft: 13,
                          }}
                        > */}
									{/* <span style={{ color: "black", fontSize: ".8em" }}>{`  ${new Date(
                            origin.substr(0, 10)
                          ).getDate()} ${new Date(
                            origin.substr(0, 10)
                          ).toLocaleString("en-us", {
                            month: "short",
                          })}`}</span>
                          <span style={{ color: "black", fontSize: ".8em" }}>
                                -
                              </span>
                              <span style={{ fontSize: 32 }}>{`  ${new Date(
                                depart.substr(0, 10)
                              ).getDate()} ${new Date(
                                depart.substr(0, 10)
                              ).toLocaleString("en-us", {
                                month: "short",
                              })}`}</span> */}
									<DateRangeNew />
									{/* </Box> */}
									{/* {trip == 'oneway' ? (
										<DesktopDateBus />
									) : trip == 'roundtrip' ? (
										<DateRangeNew />
									) : (
										<DesktopDateBus />
									)} */}
									{/* <DateRangeNew /> */}
									{/* <DesktopDateNew /> */}
									{/* </div> */}
								</Grid>
								<Grid item xs={12} lg={3}>
									<Box
										style={{
											border: '1px solid #767b8e',
											borderRadius: 5,
											marginTop: 20,
										}}>
										{/* <span style={{ fontSize: 38 }}>01</span>
                      <br></br>
                      <span style={{ fontSize: 12 }}>Travellers</span> */}
										<ul class='menu2'>
											<li>
												<a
													onClick={() =>
														hoteltoggle
															? dispatch(setHotelToggle(false))
															: dispatch(setHotelToggle(true))
													}
													style={{
														padding: 8,
														fontSize: 12,
														borderRadius: 4,
														overflow: 'hidden',
														whiteSpace: 'nowrap',
														textOverflow: 'ellipsis',
														fontWeight: 600,
														cursor: 'pointer',
													}}>
													<span style={{ fontSize: 14 }}>
														Travellers {room.length} Room
													</span>
												</a>
												{hoteltoggle ? (
													<>
														{' '}
														<ul
															style={{
																padding: 5,
																// height: null,
																width: matches ? 264 : 350,
																border: '1px solid grey',
															}}>
															{room.map((i, index) => {
																return (
																	<li>
																		<Box
																			style={{ padding: 5 }}
																			// display='flex'
																			// justifyContent='space-between'
																			// textAlign='center'
																			// alignItems='center'
																		>
																			<BodyText
																				four
																				textStyle={{
																					color: 'black',
																					fontSize: 14,
																					fontWeight: 500,
																					fontFamily: 'Poppins',
																				}}>
																				Room {index + 1}
																			</BodyText>
																			<Box
																				display='flex'
																				justifyContent='space-between'>
																				<Box>
																					<span
																						style={{
																							color: 'black',
																							fontSize: 14,
																							fontWeight: 500,
																							fontFamily: 'Poppins',
																						}}>
																						Adult
																					</span>
																					<span
																						style={{
																							fontSize: 10,
																							fontWeight: 'grey',
																							marginLeft: 5,
																						}}>
																						Above 12 years
																					</span>
																					<Box
																						display='flex'
																						alignItems='center'>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}
																							onClick={() => {
																								dispatch(
																									setAdultH(
																										`${
																											adultH[index] - 1
																										}A${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								-
																							</BodyText>
																						</Box>
																						<Box
																							// ml={'15px'}
																							style={{
																								cursor: 'pointer',
																								background: 'lightgrey',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'black',
																								}}>
																								{adultH[index]
																									? adultH[index]
																									: 0}
																							</BodyText>
																						</Box>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																							}}
																							onClick={() => {
																								dispatch(
																									setAdultH(
																										`${
																											adultH[index]
																												? adultH[index] + 1
																												: 0 + 1
																										}A${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								+
																							</BodyText>
																						</Box>
																					</Box>
																				</Box>
																				<Box>
																					<span
																						style={{
																							color: 'black',
																							fontSize: 14,
																							fontWeight: 500,
																							fontFamily: 'Poppins',
																						}}>
																						Child
																					</span>
																					<span
																						style={{
																							fontSize: 10,
																							fontWeight: 'grey',
																							marginLeft: 5,
																						}}>
																						Below 12 years
																					</span>
																					<Box
																						display='flex'
																						alignItems='center'>
																						<Box
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}
																							onClick={() => {
																								dispatch(
																									setChildH(
																										`${
																											childH[index] - 1
																										}C${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								-
																							</BodyText>
																						</Box>
																						<Box
																							// ml={'15px'}
																							style={{
																								cursor: 'pointer',
																								background: 'lightgrey',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'black',
																								}}>
																								{childH[index]
																									? childH[index]
																									: 0}
																							</BodyText>
																						</Box>
																						<Box
																							// ml={'15px'}
																							style={{
																								cursor: 'pointer',
																								background: 'rgb(0, 55, 114)',
																								padding: '0px 10px',
																								// borderRadius: '5px',
																							}}
																							onClick={() => {
																								dispatch(
																									setChildH(
																										`${
																											childH[index]
																												? childH[index] + 1
																												: 0 + 1
																										}C${index}`
																									)
																								);
																							}}>
																							<BodyText
																								textStyle={{
																									fontSize: 16,
																									color: 'white',
																								}}>
																								+
																							</BodyText>
																						</Box>
																					</Box>
																				</Box>
																			</Box>
																		</Box>
																		<Box
																			display='flex'
																			style={{
																				// textAlign: "end",
																				paddingRight: '27px',
																				marginTop: 10,
																			}}>
																			{Array.from(Array(childH[index])).map(
																				(i, ind) => {
																					return (
																						<Box ml={ind === 0 ? '' : '5px'}>
																							<label for='cars'>Age:</label>
																							<select
																								onChange={
																									(e) =>
																										dispatch(
																											index === 0
																												? setChildAge1(
																														`${e.target.value}C${ind}`
																												  )
																												: index === 1
																												? setChildAge2(
																														`${e.target.value}C${ind}`
																												  )
																												: index === 2
																												? setChildAge3(
																														`${e.target.value}C${ind}`
																												  )
																												: index === 3
																												? setChildAge4(
																														`${e.target.value}C${ind}`
																												  )
																												: ''
																										)
																									//  console.log('Age',e.target.value)
																								}
																								name='cars'
																								id='cars'
																								style={{
																									border: '1px solid grey',
																									paddingLeft: 10,
																									paddingRight: 10,
																									marginLeft: 5,
																								}}>
																								<option value='1'>1</option>
																								<option value='2'>2</option>
																								<option value='3'>3</option>
																								<option value='4'>4</option>
																								<option value='5'>5</option>
																								<option value='6'>6</option>
																								<option value='7'>7</option>
																								<option value='8'>8</option>
																								<option value='9'>9</option>
																								<option value='10'>10</option>
																								<option value='11'>11</option>
																								<option value='12'>12</option>
																							</select>
																						</Box>
																					);
																				}
																			)}
																		</Box>
																	</li>
																);
															})}
															<li>
																<Box
																	display='flex'
																	justifyContent='space-between'
																	style={{ marginTop: 10 }}>
																	<Box
																		onClick={() => dispatch(setSelectedRoom(1))}
																		style={{
																			border: '1px solid grey',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																		}}>
																		<span style={{ fontSize: 12 }}>
																			Add Room
																		</span>
																	</Box>
																	<Box
																		onClick={() =>
																			dispatch(removeSelectedRoom(1))
																		}
																		style={{
																			border: '1px solid #ea2330',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																			background: '#ea2330',
																			color: 'white',
																		}}>
																		<span style={{ fontSize: 12 }}>
																			Remove Room
																		</span>
																	</Box>
																	<Box
																		onClick={() =>
																			dispatch(setHotelToggle(false))
																		}
																		style={{
																			border: '1px solid grey',
																			paddingLeft: '5px',
																			paddingRight: '5px',
																			cursor: 'pointer',
																			background: 'rgb(0, 55, 114)',
																			color: 'white',
																		}}>
																		<span style={{ fontSize: 12 }}>Done</span>
																	</Box>
																</Box>
															</li>
														</ul>
													</>
												) : (
													''
												)}
											</li>
										</ul>
									</Box>
								</Grid>

								<Grid item xs={12} md={6} lg={3}>
									<input
										onClick={submitForm}
										// type="submit"
										value='Search Hotel'
										style={{
											width: '100%',
											fontSize: '20px',
											borderRadius: 4,
											backgroundColor: 'rgb(0, 55, 114)',
											padding: 8,
											textAlign: 'center',
											color: 'white',
											marginTop: 20,
											cursor: 'pointer',
										}}
									/>
								</Grid>
							</Grid>
						</Form>
					</>
				)}
			</Formik>
		</>
	);
};

export default HotelForm;
