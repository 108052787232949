import React from "react";
import { Box } from "@mui/material";
import 'react-alice-carousel/lib/alice-carousel.css'; // requires a loader
import AliceCarousel from 'react-alice-carousel';
import { useSelector } from "react-redux";
const Carousels = () => {
  const {hotel}= useSelector((state)=>state.home)
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 6 },
};
const items=hotel&&hotel?.result.map((i,index)=>{
  return  <div className="item" data-value="1" style={{marginTop:20}} >
      <Box style={{width:'90%'}}>
         <Box>
             <img src={i.image} style={{width:'100%',height:145}} />
         </Box>
         <Box style={{textAlign:'initial',padding:10}}>
             <span style={{color:'#0c2f54',fontFamily:'"Poppins", sans-serif',fontWeight:'bold',fontSize:12}}>{i.destination}</span><br></br>
             <Box display='flex' alignItems='center'>
             <Box display='flex' alignItems='center'>
             <span style={{color:'#0c2f54',fontFamily:'"Poppins", sans-serif',fontWeight:'bold',fontSize:12}}>805 - Properties</span>
             </Box>
             
             </Box>
            
             
          
         </Box>
         
       </Box></div>
 
}) 
const demo = [
  <div className="item" data-value="1">1</div>,
  <div className="item" data-value="2">2</div>,
  <div className="item" data-value="3">3</div>,
  <div className="item" data-value="4">4</div>,
  <div className="item" data-value="5">5</div>,
];

  return (
    <AliceCarousel
    autoPlay
    infinite
        mouseTracking
        items={hotel?items:demo}
        autoPlayInterval={3000}

        responsive={responsive}
        controlsStrategy="alternate"
        keyboardNavigation={true}
        renderPrevButton={() => {
          return <p ></p>
        }}
        renderNextButton={() => {
          return <p ></p>
        }}
    />
  
  );
};

export default Carousels;
